import { yupResolver } from '@hookform/resolvers/yup';
import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { workarea } from 'enums/fields.ts';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
  addWorkArea,
  fetchSelectedAdminWorkAreaList,
  fetchWorkAreaList,
  getWorkArea,
  setDefaultValues,
  setWorkAreaPagination,
  updateWorkArea,
} from 'store/slices/AdminModules/workAreaSlice';
import { setWorkAreaFilters } from 'store/slices/filterSlice';
import { fetchOperatingCompany } from 'store/slices/lookupSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { isEmptyVal } from 'utils/utils';
import { AddNewWorkAreaModal } from './AddNewWorkAreaModal';
import './AdminWorkArea.scss';
import { generateSchema } from './formHelpers';

const AdminWorkArea = props => {
  const dispatch = useDispatch();
  const [addNewSiteModal, setAddNewSiteModal] = useState(false);

  const inputListData = useSelector(state => state.filter.workarea);
  const adminCrewFiltersValues = [
    { label: 'Org/Department', value: 'orgDeptDesc' },
    { label: 'Org Group', value: 'orgDeptGroup' },
  ];
  const selectedFilterRecordList = useSelector(
    state => state.workArea.recordItems,
  );
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);

  const [rows, setRows] = useState([]);
  const WorkAreaList = useSelector(state => state.workArea.workAreaList);
  const [formValues, setFormValues] = useState([
    { workAreaDesc: '', workAreaShortDesc: '', status: 'A' },
  ]);
  const currentPage = useSelector(
    state => state.workArea.pagination.currentPage,
  );
  const pagination = useSelector(state => state.workArea.pagination);
  const sortBy = useSelector(state => state.workArea.pagination.sortBy);
  const sortOrder = useSelector(state => state.workArea.pagination.sortOrder);
  const workAreaData = useSelector(state => state.workArea.workAreaData);

  const { promiseInProgress: addWorkAreaLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.addWorkArea,
    delay: 0,
  });

  const { promiseInProgress: updateWorkAreaLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.updateWorkArea,
    delay: 0,
  });

  const defaultValues = {
    ocNmbr: '',
    orgDeptGroup: '',
    orgDeptDesc: '',
    status: '',
    departmentId: '',
  };

  const schema = generateSchema();
  const methods = useForm({
    mode: 'onTouched',
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    trigger,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    dispatch(fetchOperatingCompany());
    dispatch(setActivePage(Page.ADMIN_MODULES));
    setRows(WorkAreaList);
    if (props.resetFilter) {
      props.setResetFilter(false);
      handleContinueClick([
        {
          FilterValue: '',
          input_rank: null,
          selectFilterValue: '',
          selectFilterLabel: '',
          presentIcon: '',
          filerForm: false,
          FilterLabel: '',
          selectedFilterRecords: [],
        },
      ]);
    } else {
      handleContinueClick(inputListData);
    }
  }, [dispatch]);

  const handleAddWorkArea = workAreaValue => {
    dispatch(
      addWorkArea({
        data: workAreaValue,
      }),
    );
    setAddNewSiteModal(false);
  };

  const handleUpdateWorkArea = workAreaValue => {
    dispatch(
      updateWorkArea({
        data: workAreaValue,
        recordNmbr: workAreaData.orgDeptId,
      }),
    );
    setAddNewSiteModal(false);
  };

  const handleClick = () => {
    setAddNewSiteModal(true);
  };

  const handleDoubleClick = (id, e) => {
    if (e.detail == 2) {
      dispatch(
        getWorkArea({
          id,
          callback: data => {
            setValue('ocNmbr', data.ocNmbr ? data.ocNmbr : '');
            trigger('ocNmbr');
            setValue('orgDeptDesc', data.orgDeptDesc ? data.orgDeptDesc : '');
            trigger('orgDeptDesc');
            setValue(
              'orgDeptGroup',
              data.orgDeptGroup ? data.orgDeptGroup : '',
            );
            trigger('orgDeptGroup');
            setValue(
              'departmentId',
              data.departmentId ? data.departmentId : '',
            );
            trigger('departmentId');
            setValue('ocStatus', data.status ? data.status : '');
            trigger('ocStatus');
            if (data.workArea.length) {
              setFormValues([...data.workArea]);
            } else {
              setFormValues([
                { workAreaDesc: '', workAreaShortDesc: '', status: 'A' },
              ]);
            }
            setAddNewSiteModal(true);
          },
        }),
      );
    }
  };
  const headers = [
    { key: workarea.opcostate, value: 'OPCO/State', sortkey: 'orgDeptId' },
    {
      key: workarea.orgdepartment,
      value: 'Org/Department',
      sortkey: 'orgDeptDesc',
    },
    { key: workarea.orggroup, value: 'Org Group', sortkey: 'orgDeptGroup' },
    { key: workarea.opcostatestatus, value: 'status', sortkey: 'status' },
  ];

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setWorkAreaPagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  const handleCancel = () => {
    dispatch(setDefaultValues({}));
    setValue('ocNmbr', '');
    setValue('orgDeptDesc', '');
    setValue('orgDeptGroup', '');
    setValue('departmentId', '');
    setValue('ocStatus', '');
    setFormValues([{ workAreaDesc: '', workAreaShortDesc: '', status: 'A' }]);
    setAddNewSiteModal(false);
  };

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });
    const selectedFilterDataDecorated = objDecorate(selectedFilterData);
    if (props.resetFilter) {
      dispatch(
        fetchWorkAreaList({
          currentPage: 1,
          orgDeptGroup: '',
          orgDeptDesc: '',
        }),
      );
    } else {
      dispatch(fetchWorkAreaList(selectedFilterDataDecorated));
    }
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setWorkAreaFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchWorkAreaList(filterchipDataDecorated));
  };

  return (
    <>
      <div className="aepContainer adminModuleWorkAreaContainer">
        <div className="tableContainer">
          <div className="aepBreadcrumb actionPane">
            <Row>
              <Col sm={10}>
                <>
                  <div className="filterComponent">
                    <AEPFilterComponent
                      inputListData={inputListData}
                      filterValues={adminCrewFiltersValues}
                      setInputFilterData={setWorkAreaFilters}
                      selectedFilterRecordList={selectedFilterRecordList}
                      fetchSelectedList={fetchSelectedAdminWorkAreaList}
                      handleContinueClick={handleContinueClick}
                      continueBtnDisable={continueBtnDisable}
                      handleFilterclose={handleFilterclose}
                      handleFilterChip={handleFilterChip}
                      filterChip={filterChip}
                      chipFilterValues={chipFilterValues}
                    />
                  </div>
                </>
              </Col>
              <Col sm={2}>
                <div style={{ float: 'right' }}>
                  <span onClick={e => handleClick()} title="Add Row">
                    <AddIcon />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <Table
            hover
            responsive
            striped
            bordered={false}
            className="WorkAreaTbl"
          >
            <thead>
              <tr>
                {headers.map((header, idx) => (
                  <th key={idx}>
                    {sortOrder === 'asc' && (
                      <div
                        id={header.sortkey}
                        onClick={e => handleSorting(header.sortkey, 'desc')}
                      >
                        {header.value}
                        <CaretUp style={{ marginLeft: '12px' }} />
                      </div>
                    )}
                    {sortOrder === 'desc' && (
                      <div
                        id={header.sortkey}
                        onClick={e => handleSorting(header.sortkey, 'asc')}
                      >
                        {header.value}
                        <CaretDown style={{ marginLeft: '12px' }} />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {WorkAreaList &&
                WorkAreaList.map((row, indx) => (
                  <tr
                    key={indx}
                    className="tRow table-hover-row"
                    onClick={e => handleDoubleClick(row.orgDeptId, e)}
                  >
                    <td>{row.opcostate}</td>
                    <td>{row.description}</td>
                    <td>{row.orggroup}</td>
                    <td>
                      {row.orgstatus == 'A' ||
                      row.orgstatus == '1' ||
                      row.orgstatus == 'ACTIVE'
                        ? 'ACTIVE'
                        : 'INACTIVE'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <AEPPagination
            fetchItems={fetchWorkAreaList}
            setPagination={setWorkAreaPagination}
            pagination={pagination}
            currentPage={currentPage}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
        </div>
        <AddNewWorkAreaModal
          show={addNewSiteModal}
          setShowModal={setAddNewSiteModal}
          handleAddWorkArea={handleAddWorkArea}
          handleUpdateWorkArea={handleUpdateWorkArea}
          handleCancel={handleCancel}
          workAreaData={workAreaData}
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleSubmit}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          trigger={trigger}
          reset={reset}
          methods={methods}
        />
      </div>
      <ClipLoader
        color="#009cde"
        loading={addWorkAreaLoading || updateWorkAreaLoading}
        cssOverride={{
          top: '50vh',
          left: '50vw',
          position: 'absolute',
          zIndex: 1,
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};
export { AdminWorkArea };
