import queryString from 'query-string';
import { http } from 'services/helpers/BaseApi.ts';
import _ from 'lodash';
import { tracking } from './helpers/Tracking.ts';
import { OperationEnum } from 'enums/operation.ts';
import { excelDownloadInvoice } from 'services/excelDownloadInvoice';
const RootURI = '/invoices';
const RDARoorURI = 'timesheets/findwoproj';

export const exportInvoices = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.ponum = qParams.ponum.toString();
  queryParams.siteid = qParams.siteid.toString();
  queryParams.vendor = qParams.vendor.toString();
  queryParams.invoiceDate = qParams.invoiceDate.toString();
  queryParams.weekEndDate = qParams.weekEndDate.toString();
  queryParams.workAreaId = qParams.workAreaId.toString();
  queryParams.caastatus = qParams.caastatus.toString();
  queryParams.bupath = qParams.bupath.toString();
  queryParams.weekStartDate = qParams.weekStartDate.toString();
  queryParams.maxInvNmbr = qParams.maxInvNmbr.toString();
  queryParams.vendorInvNmbr = qParams.vendorInvNmbr.toString();
  queryParams.contractNmbr = qParams.contractNmbr.toString();
  queryParams.authNmbr = qParams.authNmbr.toString();
  queryParams.invoiceNmbr = qParams?.invoiceNmbr?.toString();

  //try {
  let url = {
    name: 'invoice',
    url: `${RootURI}/export?${queryString.stringify(queryParams)}`,
  };
  await excelDownloadInvoice(url);

  // return await http.get(
  //   `${RootURI}/export?${queryString.stringify(queryParams)}`,
  //   {
  //     responseType: 'blob', // Ensure the response is a blob for file download
  //   },
  // );
  // } catch (error) {
  //   console.log('=>', error);
  //   return error;
  // }
};

export const retrieveInvoices = async qParams => {
  console.log('=>', qParams);
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.ponum = qParams.ponum.toString();
  queryParams.siteid = qParams.siteid.toString();
  queryParams.vendor = qParams.vendor.toString();
  queryParams.invoiceDate = qParams.invoiceDate.toString();
  queryParams.weekEndDate = qParams.weekEndDate.toString();
  queryParams.workAreaId = qParams.workAreaId.toString();
  queryParams.caastatus = qParams.caastatus.toString();
  queryParams.bupath = qParams.bupath.toString();
  queryParams.weekStartDate = qParams.weekStartDate.toString();
  queryParams.maxInvNmbr = qParams.maxInvNmbr.toString();
  queryParams.vendorInvNmbr = qParams.vendorInvNmbr.toString();
  queryParams.contractNmbr = qParams.contractNmbr.toString();
  queryParams.authNmbr = qParams.authNmbr.toString();
  queryParams.invoiceNmbr = qParams?.invoiceNmbr?.toString();

  try {
    return await http.get(`${RootURI}?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

export const retrieveRDAeDetails = async workorder => {
  try {
    return await http.get(`${RDARoorURI}/${workorder}`);
  } catch (error) {
    return error;
  }
};

export const retrieveInvoicesAnalytics = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.ponum = qParams.ponum.toString();
  queryParams.siteid = qParams.siteid.toString();
  queryParams.vendor = qParams.vendor.toString();
  queryParams.invoiceDate = qParams.invoiceDate.toString();
  queryParams.weekEndDate = qParams.weekEndDate.toString();
  queryParams.workAreaId = qParams.workAreaId.toString();
  queryParams.caastatus = qParams.caastatus.toString();
  queryParams.bupath = qParams.bupath.toString();
  queryParams.weekStartDate = qParams.weekStartDate.toString();
  queryParams.maxInvNmbr = qParams.maxInvNmbr.toString();
  queryParams.vendorInvNmbr = qParams.vendorInvNmbr.toString();
  queryParams.contractNmbr = qParams.contractNmbr.toString();
  queryParams.authNmbr = qParams.authNmbr.toString();
  try {
    return await http.get(
      `${RootURI}/report?${queryString.stringify(queryParams)}`,
    );
  } catch (error) {
    return error;
  }
};

export const retrieveInvoiceDetails = async invoiceNumber => {
  try {
    return await http.get(`${RootURI}/${invoiceNumber}`);
  } catch (error) {
    return error;
  }
};

export const postInvoice = async payload => {
  try {
    tracking.setTrackingData(payload, 'invoiceNumber');
    return await http.post(`${RootURI}/`, payload);
  } catch (error) {
    return error;
  }
};

export const patchInvoice = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload, 'invoiceNumber', OperationEnum.UPDATE);
    return await http.patch(
      `${RootURI}/${payload.invoiceNumber}`,
      payload.data,
    );
  } catch (error) {
    return error;
  }
};

export const retreiveSelectedInvoiceList = async data => {
  const queryParams = {
    limit: 500,
  };
  if (data.hasOwnProperty('search')) {
    queryParams.search = data.search;
  }
  let url;
  if (data.columnname != 'vendorName' && data.columnname != 'vendor') {
    url = `invoices/distinct/records/${data.columnname}?${queryString.stringify(
      queryParams,
    )}`;
  } else {
    url = `/vendors/list?${queryString.stringify(queryParams)}`;
  }
  try {
    return await http.get(url);
  } catch (error) {
    return error;
  }
};

export const submitToMaximo = async payload => {
  try {
    return await http.get(
      `${RootURI}/${payload.invoiceNumber}/submit-to-maximo-create-invoice`,
      payload.data,
    );
  } catch (error) {
    return error;
  }
};

export const validateInvoice = async payload => {
  try {
    return await http.get(
      `${RootURI}/${payload.invoiceNumber}/submit-to-maximo-ps-validation`,
    );
  } catch (error) {
    return error;
  }
};

export const attachInvoiceDocuments = async payload => {
  try {
    return await http.get(
      `${RootURI}/${payload.invoiceNumber}/submit-to-maximo-attach-document`,
    );
  } catch (error) {
    return error;
  }
};
