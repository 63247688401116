import * as Yup from 'yup';
import _ from 'lodash';
import { isEmptyVal } from 'utils/utils';

export const generateSchema = (equipmentType, ownershipType) => {
  return Yup.object().shape({
    name: Yup.string().required('This field is required'),
    contractorname: Yup.string().required('This field is required'),
    mode: Yup.string(),
    lastUpdatedDate: Yup.date()
      .required('This field is required !')
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError('Only Date format is allowed!'),
    bidrateLaborRates: Yup.array().of(
      Yup.object().shape({
        laborCode: Yup.string()
          .required('This field is required')
          .max(40, 'Character length should be less than 40')
          .nullable()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        laborDescription: Yup.string()
          .nullable()
          .required('This field is required')
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),

        effStartDate: Yup.date()
          .required('This field is required')
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date format is allowed!')
          .nullable(),
        location: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only alphabets are allowed !'),
        straightTime: Yup.number()
          .typeError('Only Numbers are allowed')
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .optional(),
        overtime: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        doubleTime: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        strtTimeShft1: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        strtTimeShft2: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        strtTimeShft3: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        otTimeShft1: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        otTimeShft2: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        otTimeShft3: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        dtTimeShft1: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        dtTimeShft2: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        dtTimeShft3: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        holidayPay: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        travelRate: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        emergencyCallOut: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        mobilization: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        demobilization: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        perdiem: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        daily: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        weekly: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        monthly: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        rhx: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        dtx: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        bidrateLaborRateNmbr: Yup.number()
          .nullable()
          .optional()
          .typeError('Only Numbers are allowed'),
        bidrateNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        updatedDate: Yup.date()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .nullable()
          .optional()
          .typeError('Date format is allowed'),
        updatedBy: Yup.string()
          .max(50, 'Character length should be less than 50')
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only alphabets are allowed !'),
        enteredBy: Yup.string()
          .max(25, 'Character length should be less than 25')
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only alphabets are allowed !'),
        enteredDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Date format is allowed'),
        effEndDate: Yup.date()
          .nullable()
          .optional()
          .typeError('Date format is allowed'),
        peakSeason: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        laborCategory: Yup.string()
          .max(100, 'Character length should be less than 100')
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only alphabets are allowed !'),
        workCategory: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only AlphaNumerics are allowed '),
        standby: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        baseWage: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
      }),
    ),
    bidrateEquipmentRates: Yup.array().of(
      Yup.object().shape({
        eqpmntCode: Yup.string()
          .max(40, 'Character length should be less than 40')
          .nullable()
          .required('This field is required')
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        eqpmntDescription: Yup.string()
          .nullable(true)
          .required('This field is required')
          .transform((_, val) => (val === '' ? null : val))
          .nullable()
          .typeError('Only alphabets are allowed !'),
        location: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only Alphanumerics are allowed !'),
        effStartDate: Yup.date()
          .required('This field is required !')
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date format is allowed'),
        ownedOrLeased: Yup.string()
          .max(20, 'Character length should be less than 20')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !')
          .oneOf([...ownershipType, null], 'Invalid Value'),
        eqpmntCategory: Yup.string()
          .max(30, 'Character length should be less than 30')
          .nullable()
          .optional()
          .typeError('Only alphabets are allowed !'),
        //eqpmntCategory: Yup.string().max(25).nullable().optional(),
        eqpmntType: Yup.string()
          .max(30, 'Character length should be less than 30')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !')
          .oneOf([...equipmentType, null], 'Invalid Value'),
        opRate: Yup.number()
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .optional()
          .typeError('Only Numbers are allowed'),
        stbyRate: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        ovrTimeRate: Yup.number()
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .optional()
          .typeError('Only Numbers are allowed'),
        volumecfm: Yup.string()
          .max(30, 'Character length should be less than 30')
          .nullable()
          .optional()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only Alphanumerics are allowed'),
        size: Yup.string()
          .max(20, 'Character length should be less than 20')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        fuelrentalRate: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        eqpmntRentalRate: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        usgModifier: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        usgDsctPct: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        usgDsctRate: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        baseFuelRate: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        fuelTolerance: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        fuelGallonsPerHour: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        eqpmntDriveType: Yup.string()
          .max(20, 'Character length should be less than 20')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        horsepower: Yup.string()
          .nullable()
          .optional()
          .max(30, 'Character length should be less than 30')
          .transform((curr, orig) => (orig === '' ? null : curr)),
        volumeOfWaterGpm: Yup.string()
          .nullable()
          .optional()
          .max(30, 'Character length should be less than 30')
          .transform((curr, orig) => (orig === '' ? null : curr)),
        nmWaterGunsLances: Yup.string()
          .nullable()
          .optional()
          .max(30, 'Character length should be less than 30')
          .transform((curr, orig) => (orig === '' ? null : curr)),
        roundTripMiles: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        costPerMile: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        lumpsumRoundTripTruckingChrgs: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        mobilization: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        demobilization: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        effEndDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date format is allowed'),
        bidrateEqptNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        bidrateNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        updatedDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date format is allowed'),
        updatedBy: Yup.string()
          .max(50, 'Character length should be less than 50')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val)),
        enteredBy: Yup.string()
          .max(25, 'Character length should be less than 25')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val)),
        enteredDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date format is allowed'),
        unitOfMeasure: Yup.string()
          .max(20, 'Character length should be less than 20')
          .required('This field is required !')
          .nullable()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        workCategory: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required !')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only AlphaNumerics are allowed'),
      }),
    ),
    bidrateUnionRates: Yup.array().of(
      Yup.object().shape({
        billingCode: Yup.string()
          .required('This field is required')
          .max(40, 'Character length should be less than 40')
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only alphabets are allowed !'),
        billingDescription: Yup.string()
          .nullable()
          .required('This field is required !')
          .transform((_, val) => (val === '' ? null : val)),
        location: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only alphabets are allowed !'),
        effStartDate: Yup.date()
          .required('This field is required !')
          .typeError('Only Date format is allowed!')
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr)),
        local: Yup.string()
          .max(50, 'Character length should be less than 50')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only alphabets are allowed !'),
        cost: Yup.number()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .optional()
          .nullable()
          .typeError('Only Numbers are Allowed'),
        effEndDate: Yup.date()
          .required('This field is required !')
          .optional()
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date format is allowed!'),
        workCategory: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required !')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only AlphaNumerics are allowed '),
        stPercentage: Yup.number()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed!')
          .optional()
          .nullable(),
        otDtPercentage: Yup.number()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed!')
          .optional()
          .nullable(),
      }),
    ),
    bidrateReimbursibleMarkups: Yup.array().of(
      Yup.object().shape({
        rmbrslMarkUpCode: Yup.string()
          .max(40, 'Character length should be less than 40')
          .required('This field is required!')
          .transform((_, val) => (val === '' ? null : val))
          .nullable()
          .typeError('Only alphabets are allowed !'),
        description: Yup.string()
          .required('This field is required !')
          .transform((_, val) => (val === '' ? null : val))
          .nullable()
          .typeError('Only alphabets are allowed !'),
        location: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only alphabets are allowed !'),
        effStartDate: Yup.date()
          .required('This field is required !')
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date format is allowed!'),
        price: Yup.number()
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .optional()
          .typeError('Only Numbers are allowed'),
        basePriceMarkup: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        mscMarkUp: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        dlvryMarkUp: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        effEndDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date format is allowed!'),
        bidrateReimMarkNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        bidrateNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        updatedDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date format is allowed!'),
        updatedBy: Yup.string()
          .max(50, 'Character length should be less than 50')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        enteredBy: Yup.string()
          .max(25, 'Character length should be less than 25')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        enteredDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only alphabets are allowed !'),
        workCategory: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required !')
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .nullable()
          .typeError('Only AlphaNumerics are allowed'),
        unitOfMeasure: Yup.string()
          .max(20, 'Character length should be less than 20')
          .required('This field is required !')
          .nullable()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        rmbrslMarkUpDescription: Yup.string()
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
      }),
    ),
    bidrateCus: Yup.array().of(
      Yup.object().shape({
        unitCode: Yup.string()
          .max(40, 'Character length should be less than 40')
          .required('This field is required !')
          .transform((_, val) => (val === '' ? null : val))
          .nullable()
          .typeError('Only alphabets are allowed !'),
        unitDescription: Yup.string()
          .required('This field is required !')
          .transform((_, val) => (val === '' ? null : val))
          .nullable()
          .typeError('Only alphabets are allowed !'),
        unitOfMeasure: Yup.string()
          .max(20, 'Character length should be less than 20')
          .required('This field is required !')
          .nullable()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        location: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only alphabets are allowed !'),
        effStartDate: Yup.date()
          .required('This field is required !')
          .transform((curr, orig) => (orig === '' ? null : curr))
          .nullable()
          .typeError('Only Date is allowed!'),
        installPrice: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        removePrice: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        transferPrice: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        baseFuelRate: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        fuelTolerance: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        fuelFactor: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        deEnergizedMarkDownPct: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        smallJobMarkUpPct: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        bidrateNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        updatedDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!'),
        updatedBy: Yup.string()
          .max(50, 'Character length should be less than 50')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        enteredBy: Yup.string()
          .max(25, 'Character length should be less than 25')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        enteredDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is allowed!'),
        workCategory: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required !')
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .nullable()
          .typeError('Only AlphaNumerics are allowed'),
        effEndDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is allowed!'),
        bidrateCuNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
      }),
    ),
    bidrateUnitPrices: Yup.array().of(
      Yup.object().shape({
        unitCode: Yup.string()
          .max(40, 'Character length should be less than 40')
          .required('This field is required !')
          .transform((_, val) => (val === '' ? null : val))
          .nullable()
          .typeError('Only alphabets are allowed !'),
        unitDescription: Yup.string()
          .required('This field is required !')
          .transform((_, val) => (val === '' ? null : val))
          .nullable()
          .typeError('Only alphabets are allowed !'),
        unitOfMeasure: Yup.string()
          .max(20, 'Character length should be less than 20')
          .required('This field is required !')
          .nullable()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        location: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required !')
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .nullable()
          .typeError('Only alphabets are allowed !'),
        effStartDate: Yup.date()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .required('This field is required !')
          .typeError('Only Date is  allowed!')
          .nullable(),
        estQnt: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        ttlQnt: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        equipmentLaborUnitprice: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        laborUnitprice: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        equipmentUnitprice: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        materialUnitprice: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        totalUnitprice: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        effEndDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is allowed'),
        bidrateUnitNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        bidrateNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        updatedDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!'),
        updatedBy: Yup.string()
          .max(50, 'Character length should be less than 50')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        enteredBy: Yup.string()
          .max(25, 'Character length should be less than 25')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        enteredDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!'),
        totalLinePrice: Yup.number()
          .nullable()
          .typeError('Only Numbers are allowed')
          .transform((curr, orig) => (orig === '' ? null : curr)),
        workCategory: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required !')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only AlphaNumerics are allowed'),
      }),
    ),
    bidrateUndergrndMltplrRates: Yup.array().of(
      Yup.object().shape({
        code: Yup.string()
          .max(40, 'Character length should be less than 40')
          .required('This field is required !')
          .transform((_, val) => (val === '' ? null : val))
          .nullable()
          .typeError('Only alphabets are allowed !'),
        unitDescription: Yup.string()
          .required('This field is required !')
          .transform((_, val) => (val === '' ? null : val))
          .nullable()
          .typeError('Only alphabets are allowed !'),
        effStartDate: Yup.date()
          .required('This field is required !')
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!')
          .nullable(),
        location: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only alphabets are allowed !'),
        numberField: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        baseFuelRate: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        fuelTolerance: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        fuelFactor: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        effEndDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!'),
        bidrateUMNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        bidrateNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        updatedDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!'),
        updatedBy: Yup.string()
          .max(50, 'Character length should be less than 50')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        enteredBy: Yup.string()
          .max(25, 'Character length should be less than 25')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        enteredDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!'),
        workCategory: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required !')
          .nullable()
          .typeError('Only Alphanumerics are allowed !!')
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          ),
      }),
    ),
    bidrateTaskCodes: Yup.array().of(
      Yup.object().shape({
        taskCode: Yup.string()
          .max(40, 'Character length should be less than 40')
          .required('This field is required !')
          .nullable()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        taskDescription: Yup.string()
          .required('This field is required !')
          .nullable()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        unitOfMeasure: Yup.string()
          .max(20, 'Character length should be less than 20')
          .nullable()
          .required('This field is required !')
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        effStartDate: Yup.date()
          .required('This field is required !')
          .nullable()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!'),
        location: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required !')
          .nullable()
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          )
          .typeError('Only alphabets are allowed !'),
        price: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        effEndDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!'),
        bidrateTaskNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        bidrateNmbr: Yup.number()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Numbers are allowed'),
        updatedDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!'),
        updatedBy: Yup.string()
          .max(50, 'Character length should be less than 50')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        enteredBy: Yup.string()
          .max(25, 'Character length should be less than 25')
          .nullable()
          .optional()
          .transform((_, val) => (val === '' ? null : val))
          .typeError('Only alphabets are allowed !'),
        enteredDate: Yup.date()
          .nullable()
          .optional()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .typeError('Only Date is  allowed!'),
        workCategory: Yup.string()
          .max(100, 'Character length should be less than 100')
          .required('This field is required !')
          .nullable()
          .typeError('Only Alphanumerics are allowed!')
          .transform((_, val) =>
            val === '' ? null : typeof val == 'number' ? val.toString() : val,
          ),
      }),
    ),
  });
};
