import react, { useRef, useState } from 'react';
import { Button, Modal, Row, Col, ProgressBar } from 'react-bootstrap';
import { PDFIcon } from 'assets/images/PDFIcon/PDFIcon';
import { CheckIcon } from 'assets/images/CheckIcon/CheckIcon';
import { UploadPDFIcon } from 'assets/images/UploadPDFIcon/UploadPDFIcon';
import { Divider } from 'components/common/Divider/Divider';
import { CloseIcon } from 'assets/images/CloseIcon/CloseIcon';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { useMediaQuery } from 'react-responsive';
import { saveUploadTimesheet } from 'store/slices/timesheet/timesheetSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmptyVal } from 'utils/utils';

const UploadTimesheet = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [errorFlag, setErrorFlag] = useState(false);
  const [dataFromExcel, setDataFromExcel] = useState();

  const loader = counter => {
    if (counter <= 100) {
      setCount(counter);
      setTimeout(() => {
        loader(counter + 1);
      }, 1000);
    }
  };

  const handleUploadFile = e => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    let selectedFile = null;
    if (e?.dataTransfer?.files?.length) {
      selectedFile = e.dataTransfer.files[0];
    } else if (target.files && target.files[0]) {
      selectedFile = e.target.files[0];
    }
    console.log({ selectedFile });
    if (!isEmptyVal(selectedFile)) {
      /*Maximum allowed size in bytes 12MB */
      const maxAllowedSize = 12 * 1024 * 1024;
      if (selectedFile.size < maxAllowedSize) {
        setUploadedFile(selectedFile.name.substring(12));
      }
    }
    setDataFromExcel(selectedFile);
    loader(100);
  };

  const [uploadedFile, setUploadedFile] = useState();
  const [showAttachment, setShowAttachment] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  const handleCancelUpload = () => {
    props.setViewModal(false);
    setDataFromExcel();
    setUploadedFile();
    setCount(0);
  };

  const setData = e => {
    if (dataFromExcel) {
      dispatch(saveUploadTimesheet(dataFromExcel));
      navigate('/timesheets');
      setDataFromExcel();
      setUploadedFile();
      setCount(0);
      props.setViewModal(false);
    }
  };

  const handleDeleteFileFromArray = () => {
    setUploadedFile();
  };

  const inputRef = useRef(null);

  return (
    <Modal
      show={props.viewModal}
      onHide={() => props.setViewModal(false)}
      size="md"
      dialogClassName="invoicePreviewDialog"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            height: '26px',
          }}
        >
          <div className="backArrow">
            <BackArrowIcon styles={{ marginTop: '-4px' }} />
          </div>
          Upload Documents
          <div
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={e => props.setViewModal(false)}
          >
            <CloseIcon />
          </div>
        </div>
      </Modal.Header>
      <Divider />
      <Modal.Body className="invoicePreviewModalBody">
        <div className="uploadSection">
          <form
            id="form-file-upload"
            onDragEnter={e => e.preventDefault()}
            onDragOver={e => e.preventDefault()}
            onDrop={e => handleUploadFile(e)}
            onSubmit={e => e.preventDefault()}
          >
            <input
              type="file"
              id="input-file-upload"
              onChange={e => handleUploadFile(e)}
              ref={inputRef}
            />
            <label id="label-file-upload" htmlFor="input-file-upload">
              <div className="uploadContent">
                <Row>
                  <Col sm={3}>
                    <div className="uploadIconWrapper">
                      <UploadPDFIcon />
                    </div>
                  </Col>
                  <Col sm={9}>
                    <div className="uploadHeadline">
                      Select a file to upload
                    </div>
                    <div className="uploadSubheading">
                      {isMobile ? '' : 'or drag and drop your files here'}{' '}
                    </div>
                  </Col>
                </Row>
                <button className="upload-button"></button>
              </div>
            </label>
          </form>

          <div className="uploadedFiles">
            {uploadedFile !== undefined ? (
              <div className="file">
                <Row>
                  <Col sm={1}>
                    <div className="pdfIcon">
                      <PDFIcon />
                    </div>
                  </Col>
                  <Col sm={2}>
                    {errorFlag ? (
                      <p style={{ color: '#AF2218' }}>Selected Wrong file</p>
                    ) : (
                      <p> {uploadedFile}</p>
                    )}
                  </Col>
                  <Col sm={7}>
                    <ProgressBar now={count} />
                    <span>{count}% done</span>
                  </Col>
                  <Col sm={1}>
                    <div className="completed">
                      {errorFlag ? '' : <CheckIcon />}
                    </div>
                  </Col>
                  <Col sm={1}>
                    <div
                      className="cancel"
                      onClick={() => handleDeleteFileFromArray()}
                    >
                      x
                    </div>
                  </Col>
                </Row>
              </div>
            ) : null}
          </div>
        </div>
        <div className="btnArea">
          {count === 100 ? (
            <Button variant="primary" onClick={setData}>
              Upload
            </Button>
          ) : (
            <Button
              variant="primary"
              style={{ cursor: 'not-allowed' }}
              disabled
              onClick={setData}
            >
              Upload
            </Button>
          )}
          <Button variant="secondary" onClick={handleCancelUpload}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { UploadTimesheet };
