import { AttachmentsIcon } from 'assets/images/AttachmentsIcon/AttachmentsIcon';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { CheckIcon } from 'assets/images/CheckIcon/CheckIcon';
import { CloseIcon } from 'assets/images/CloseIcon/CloseIcon';
import { DownloadIcon } from 'assets/images/DownloadIcon/DownloadIcon';
import { ErrorIcon } from 'assets/images/ErrorIcon/ErrorIcon';
import { MaximizeIcon } from 'assets/images/MaximizeIcon/MaximizeIcon';
import { MinimizeIcon } from 'assets/images/MinimizeIcon/MinimizeIcon';
import { PDFIcon } from 'assets/images/PDFIcon/PDFIcon';
import { Divider } from 'components/common/Divider/Divider';
import { InvoiceStatusDisplayValue } from 'constants/statusValues';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ClipLoader from 'react-spinners/ClipLoader';
// @ts-ignore
import { Page } from 'enums/page.ts';
// @ts-ignore
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
// @ts-ignore
import DragAndDrop from 'components/common/DragAndDrop/DragAndDrop';
import { InvoiceStatus, PurchaseOrderStatus } from 'enums/statuses.ts';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { deleteFile, uploadFiles } from 'services/fileUploadService';
import { InvoiceViewAttachment } from '../InvoiceAttachment/InvoiceAttachment';

import { confirm } from 'components/Dialogs/Confirm';
import { fileViewer } from 'components/Dialogs/FileViewer';
import {
  displayCompletionFormStatus,
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { ReviewerModal } from 'components/common/modals/ReviewerModal';
import { BuPath } from 'constants/buPath';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { ROUTES_STRING } from 'constants/routes';
import { ModuleACL, UserType } from 'enums/entitlements.ts';
import _ from 'lodash';
import { getClassificationLabel } from 'pages/Timesheet/utils';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { excelInvoiceDownload } from 'services/excelInvoiceDownload';
import {
  fetchInvoiceDetails,
  resetInvoiceDetails,
  sendInvoiceToMaximo,
  setCreateInvoiceError,
  setInvoiceDetails,
  submitToMaximoAttachDocument,
  submitToMaximoPSValidation,
  updateInvoice,
} from 'store/slices/Invoice/invoiceDetailSlice';
import {
  createInvoice,
  setInvoicePagination,
} from 'store/slices/Invoice/invoiceSlice';
import { fetchInvoiceData, fetchInvoiceDates } from 'store/slices/lookupSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { returnTimesheets } from 'store/slices/timesheet/timesheetSlice';
import { IsAllowedACL } from 'utils/aclHelper';
import { currencyFormat } from 'utils/formatHelper';
import { isEmptyVal } from 'utils/utils';
import Accounting from './Accounting';
import './InvoiceDetails.scss';

const InvoiceDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // @ts-ignore
  const user = useSelector(state => state.auth);
  // @ts-ignore
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);

  // @ts-ignore
  const invoice = useSelector(state => state.invoiceDetails);
  const costData = invoice.allCostLine;
  // @ts-ignore
  const invoiceData = useSelector(state => state.lookup.invoiceData);

  // @ts-ignore
  const pagination = useSelector(state => state.purchaseOrder.pagination);
  // @ts-ignore
  const currentPage = useSelector(
    // @ts-ignore
    state => state.purchaseOrder.pagination.currentPage,
  );
  // @ts-ignore
  const sortBy = useSelector(state => state.purchaseOrder.pagination.sortBy);
  const sortOrder = useSelector(
    // @ts-ignore
    state => state.purchaseOrder.pagination.sortOrder,
  );
  const lookup = useSelector(state => state.lookup);

  const maximoInvoiceError = useSelector(
    state => state.invoiceDetails.maximoInvoiceError,
  );

  const maximoErrors = useSelector(state => state.invoiceDetails.maximoErrors);
  const maxErrorTitle = useSelector(
    state => state.invoiceDetails.maxErrorTitle,
  );
  const [fileUploadInprogress, setFileUploadInprogress] = useState(false);
  const [uploadedFilesArray, setUploadedFilesArray] = useState([]);
  const [pdfDocumentUrl, setPdfDocumentUrl] = useState('');
  const [showAttachmentPDF, setShowAttachmentPDF] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [showAttachment, setShowAttachment] = useState(false);
  const [selectedVerifier, setSelectedVerifier] = useState('');
  const [key, setKey] = useState('activities');
  const [retentionHeld, setRetentionHeld] = useState(false);
  const [submitToMaximo, setSubmitToMaximo] = useState(true);
  const [vendorInvoiceNumber, setVendorInvoiceNumber] = useState('');
  const [carryCommentsTimesheet, setCarryCommentsTimesheet] = useState(false);
  const [defaultInvocieVerifier, setInvoiceDetailsVerifier] = useState([]);

  // @ts-ignore
  const [newInvoice, setNewInvoice] = useState(false);
  const [invoiceVerifier, setInvoiceVerifier] = useState('');
  const [invoiceApprover, setInvoiceApprover] = useState('');
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [filterTabs, setFilterTabs] = useState([]);
  const [showReviewerDisagreeModal, setShowReviewerDisagreeModal] =
    useState(false);
  const [reviewerComment, setReviewerComment] = useState('');
  const [paymentHistoryDate, setpaymentHistoryDate] = useState(new Date());
  const tabs = [
    { key: 'activities', value: 'Activities' },
    { key: SERVICE_TYPES.LABOR, value: 'Labor' },
    { key: SERVICE_TYPES.EQUIPMENT, value: 'Equipment' },
    { key: SERVICE_TYPES.REIMBURSABLE_MARKUP, value: 'Reimbursable Markup' },
    { key: SERVICE_TYPES.UNIT_PRICE_TASK, value: 'Unit Price Task' },
    { key: SERVICE_TYPES.LUMPSUM, value: 'Expense' },
    { key: SERVICE_TYPES.CU, value: 'CU' },
    { key: 'invAdj', value: 'Invoice Adjustments' },
  ];

  const headers = {
    activities: [
      'Activity Number',
      'Activity Description',
      'Labor Hours',
      'Equipment Hours',
      'Total Lumpsum',
      'Reimbursable Quantity',
      'Uptask Quantity',
      'Amount',
    ],
    [SERVICE_TYPES.LABOR]: [
      'Code',
      'Name',
      'Type',
      // 'Shift',
      'Rate',
      'Hours',
      'Unit',
      'Amount',
    ],
    [SERVICE_TYPES.EQUIPMENT]: [
      'Description',
      'Type',
      // 'Shift',
      'Rate',
      'Hours',
      'Unit',
      'Amount',
    ],
    [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [
      'Code',
      // 'Shift',
      'Rate',
      'Quantity',
      'Base Price Markup',
      'Delivery Markup',
      'Miscellaneous Markup',
      'Amount',
    ],
    [SERVICE_TYPES.UNIT_PRICE_TASK]: [
      'Code',
      // 'Shift',
      'Rate',
      'Quantity',
      'Amount',
    ],
    [SERVICE_TYPES.LUMPSUM]: [
      'Code',
      'Name',
      // 'Shift',
      'Rate',
      'Quantity',
      'Amount',
    ],
    [SERVICE_TYPES.CU]: ['Code', 'Rate', 'Units', 'Action', 'Amount'],
    invAdj: ['Type', 'Amount'],
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(setActivePage(Page.INVOICE));

    const invoice_Verifiers = lookup?.userInvoiceEntitlements
      ?.filter(obj => obj.approverType === 'V')
      ?.slice()
      .sort((option1, option2) =>
        String(option1.name).trim().localeCompare(String(option2.name).trim()),
      );

    setInvoiceDetailsVerifier(invoice_Verifiers);

    if (invoice.invoiceVerifiers && !invoice.invoiceVerifiers.length) {
      const entitlementObj = lookup?.userInvoiceEntitlements?.filter(
        obj =>
          obj.userid.toLowerCase() ===
          invoice?.purchaseOrder?.invapprover?.toLowerCase(),
      );
      setInvoiceVerifier(entitlementObj[0]?.userid);
    }
  }, []);

  const handleCancelUpload = () => {
    setShowAttachment(false);
  };

  const { promiseInProgress: invoiceLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.sendToMaximo,
    delay: 0,
  });

  useEffect(() => {
    setLoader(true);
    dispatch(resetInvoiceDetails());

    const recordNumArray = location.pathname.split('/');
    if (recordNumArray?.length) {
      const recordNum = recordNumArray[recordNumArray.length - 1];
      setSubmitToMaximo(true);
      if (recordNum === 'new') {
        const poId = searchParams.get('poId');
        const timesheetIds = searchParams.get('timesheetIds');
        setCarryCommentsTimesheet(
          searchParams.get('includeComments') === 'true',
        );

        let weekStartDate = invoiceData?.weekStartDate;
        if (_.isNil(weekStartDate) && !_.isNil(invoiceData?.timesheets)) {
          weekStartDate = invoiceData.timesheets[0].workStartDate;
        }

        dispatch(
          // @ts-ignore
          fetchInvoiceData({
            poId,
            timesheetIds,
            callback: () => {
              setNewInvoice(true);
              const data = {
                bupath: purchaseOrder?.buPath,
                caastatus: InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED,
                weekStartDate: weekStartDate,
                weekEndDate: invoiceData?.weekEndDate,
                timesheets: invoiceData?.timesheets,
                carryCommentsTimesheet: carryCommentsTimesheet,
              };
              dispatch(setInvoiceDetails(data));
              dispatch(fetchInvoiceDates(poId));
              setLoader(false);
            },
          }),
        );
      } else {
        setNewInvoice(false);
        if (recordNum !== invoice?.invoiceNmbr) {
          // @ts-ignore
          dispatch(fetchInvoiceDetails(recordNum));
          setCarryCommentsTimesheet(invoiceData?.carryCommentsTimesheet);
        }
      }
      const invoice_Verifiers = lookup?.userInvoiceEntitlements
        ?.slice()
        .sort((option1, option2) =>
          String(option1.name)
            .trim()
            .localeCompare(String(option2.name).trim()),
        );

      setInvoiceDetailsVerifier(invoice_Verifiers);

      if (invoice.invoiceVerifiers && !invoice.invoiceVerifiers.length) {
        const entitlementObj = lookup?.userInvoiceEntitlements?.filter(
          obj =>
            obj.userid.toLowerCase() ===
            invoice?.purchaseOrder?.invapprover?.toLowerCase(),
        );
        setInvoiceVerifier(entitlementObj[0]?.userid);
      }
    }
  }, [invoiceData.invoiceNmbr]);

  useEffect(() => {
    setRetentionHeld(invoice.retentionWithheld);
    setVendorInvoiceNumber(invoice.vendorInvNmbr);

    if (invoice.invoiceNmbr) {
      setSubmitToMaximo(invoice.createMaxInv ? true : false);
    } else {
      setSubmitToMaximo(true);
    }
    console.log(invoice);
    const filteredTab = tabs.filter(tab => {
      return invoice[tab.key] && invoice[tab.key].length > 0;
    });
    setFilterTabs(filteredTab);
    if (invoice.invoiceVerifiers && invoice.invoiceVerifiers.length) {
      let verifierData = invoice.invoiceVerifiers.find(itm => {
        return itm.approverType === 'V';
      });
      setInvoiceVerifier(verifierData?.approver);
    } else {
      const entitlementObj = lookup?.userInvoiceEntitlements?.filter(
        obj =>
          obj.userid.toLowerCase() ===
          invoice?.purchaseOrder?.invapprover?.toLowerCase(),
      );
      setInvoiceVerifier(entitlementObj[0]?.userid);
    }

    const invoice_Verifiers = lookup?.userInvoiceEntitlements
      ?.slice()
      .sort((option1, option2) =>
        String(option1.name).trim().localeCompare(String(option2.name).trim()),
      );

    setInvoiceDetailsVerifier(invoice_Verifiers);

    setLoader(false);
  }, [invoice.invoiceNmbr]);

  useEffect(() => {
    setVendorInvoiceNumber(invoice.vendorInvNmbr);
  }, [invoice.vendorInvNmbr]);

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {},
    // resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const {
    control,
    formState: { errors },
  } = methods;

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setInvoicePagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  const handleFileUpload = files => {
    const tempUploadedFilesArray = [...uploadedFilesArray, ...files];
    setUploadedFilesArray(tempUploadedFilesArray);
  };

  const handleDeleteFileFromArray = index => {
    let updatedArray = [...uploadedFilesArray];
    updatedArray.splice(index, 1);
    setUploadedFilesArray(updatedArray);
  };

  const hideTimesheetDiv = index => {
    const sTypeDiv = document.getElementById('timesheet' + index);
    const maxDiv = document.getElementById('showMaxDiv' + index);
    const minDiv = document.getElementById('showMinDiv' + index);

    if (sTypeDiv !== null || sTypeDiv != undefined) {
      const divDisplay = sTypeDiv.style.display;
      if (divDisplay === 'none') {
        sTypeDiv.style.display = '';
        if (maxDiv !== undefined && minDiv != undefined) {
          // @ts-ignore
          maxDiv.style.display = 'none';
          minDiv.style.display = '';
        }
      } else {
        sTypeDiv.style.display = 'none';
        if (maxDiv !== undefined && minDiv != undefined) {
          // @ts-ignore
          maxDiv.style.display = '';
          minDiv.style.display = 'none';
        }
      }
    }
  };

  const hideMaximoErrorModel = () => {
    dispatch(setCreateInvoiceError({ showError: false }));
  };

  const handleInvoiceSave = async () => {
    setSaveInProgress(true);
    setLoader(true);
    const isValid = validateCostLines(costData);
    const result = checkEquality();
    if (!isValid) {
      displayError('Accounting Details can not be blank.');
      setSaveInProgress(false);
      setLoader(false);
    } else if (!result) {
      displayError(
        'The total amount distributed for invoice line does not = original invoice line amount.',
      );
      setSaveInProgress(false);
      setLoader(false);
    } else {
      if (!invoice?.invoiceNmbr) {
        let message = '';

        lookup?.invoiceDates.forEach(obj => {
          const isDuplicate =
            invoice.weekStartDate === obj.weekStartDate &&
            invoice.weekEndDate === obj.weekEndDate;
          if (isDuplicate) {
            // displayError(
            message =
              'Invoices have already been initiated for same dates worked.\n';
            // );
          }
        });
        const canSave = await confirm({
          message:
            message + 'Are you sure you would like to create the Invoice?',
          title: 'Create Invoice',
        });
        if (!canSave) {
          setSaveInProgress(false);
          setLoader(false);
          return;
        }
      }

      // if (saveInProgress) {
      //   return false;
      // }

      const invoiceStatus = InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED;

      let invoiceCostData = [];
      if (invoice.allCostLine.length) {
        for (let i = 0; i < invoice.allCostLine.length; i++) {
          for (let j = 0; j < invoice.allCostLine[i].costline.length; j++) {
            let costdata = invoice.allCostLine[i].costline[j];
            invoiceCostData.push({
              costlinenum: j + 1,
              gldebitacct: `${costdata.glbu}-${costdata.dept}-${costdata.accountNmbr}-${costdata.workorder}-${costdata.projectBusinessUnit}-${costdata?.abmsProject}-${costdata.abmsActivity}-${costdata.costComponent}`,
              percentage: costdata?.percentage,
              orgid: invoice.purchaseOrder.orgid,
              siteid: invoice.purchaseOrder.siteid,
              invoicelinenum: j + 1, // need to add logic for trans/generation
              activitynum: invoice.allCostLine[i].activity,
              linecost: parseFloat(costdata?.linecost),
              unitcost: costdata?.linecost < 0 ? -1 : 1,
              quantity:
                costdata?.linecost < 0
                  ? costdata?.linecost * -1
                  : costdata?.linecost,
              invoicecostlinenum: costdata?.invoicecostlinenum,
            });
          }
        }
      } else {
        for (let i = 0; i < invoice.invoiceCosts.length; i++) {
          let costdata = invoice.invoiceCosts[i];
          invoiceCostData.push({
            costlinenum: i + 1,
            gldebitacct: costdata.gldebitacct,
            percentage: costdata?.percentage,
            orgid: invoice.purchaseOrder.orgid,
            siteid: invoice.purchaseOrder.siteid,
            polinenum: costdata.polinenum,
            invoicelinenum: i + 1, // need to add logic for trans/generation
            activitynum: costdata.activitynum,
            linecost: parseFloat(costdata?.linecost),
            unitcost: costdata?.linecost < 0 ? -1 : 1,
            quantity:
              costdata?.linecost < 0
                ? costdata?.linecost * -1
                : costdata?.linecost,
            invoicecostlinenum: costdata?.invoicecostlinenum,
          });
        }
      }
      const payloadData = {
        poid: purchaseOrder.poid || invoice.poid,
        vendorInvNmbr: vendorInvoiceNumber,
        weekStartDate: invoice.weekStartDate,
        weekEndDate: invoice.weekEndDate,
        bupath: invoice.bupath,
        invoiceCosts: invoiceCostData,
        caastatus: invoiceStatus,
        invoiceLabors: invoice[SERVICE_TYPES.LABOR],
        invoiceCus: invoice[SERVICE_TYPES.CU],
        invoiceEquipments: invoice[SERVICE_TYPES.EQUIPMENT],
        invoiceReimbursables: invoice[SERVICE_TYPES.REIMBURSABLE_MARKUP],
        invoiceUptasks: invoice[SERVICE_TYPES.UNIT_PRICE_TASK],
        invoiceLumpsums: invoice[SERVICE_TYPES.LUMPSUM],
        timesheets: invoice.timesheets,
        invoiceLines: invoice.invoiceLines,
        carryCommentsTimesheet,
        exceedsPolineThreshold: invoice?.exceedsPOLineThreshold,
        exceedsPoCommitted: invoice?.exceedsPOCommitted,
        createMaxInv: submitToMaximo ? true : false,
        apglbu: purchaseOrder.apglbu || invoice.apglbu,
        invoiceAmount: invoice?.invoiceAmount,
        invoiceDate: !_.isNil(invoice?.invoiceDate)
          ? invoice.invoiceDate
          : new Date(),
        retentionWithheld: !_.isNil(invoice?.retentionWithheld)
          ? invoice.retentionWithheld
          : false,
        fuelAdjCost: invoice?.fuelAdjCost,
        travelAmount: invoice?.travelAmount,
        smallJobAmount: invoice?.smallJobAmount,
        deEnergizedAmount: invoice?.deEnergizedAmount,
        invoiceStatushistories: [
          {
            caastatus: invoiceStatus,
            comments: reviewerComment ? reviewerComment : null,
          },
        ],
      };

      // Invoice Verifier
      if (invoiceVerifier && invoiceVerifier !== '') {
        const entitlementObj = lookup?.userInvoiceEntitlements?.filter(
          obj => obj.userid === invoiceVerifier,
        );
        if (entitlementObj.length) {
          payloadData['invoiceVerifiers'] = [
            {
              approver: invoiceVerifier,
              invoiceNmbr: invoice?.invoiceNmbr,
              bupath: purchaseOrder.buPath,
              approverType: 'V',
            },
          ];
        } else if (
          BuPath.DIST.includes(invoice.bupath) &&
          (invoice?.caastatus === InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED ||
            invoice?.caastatus ===
              InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED ||
            invoice?.caastatus ===
              InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED)
        ) {
          displayError('Please Select Invoice Verifier');
          setSaveInProgress(false);
          setLoader(false);
          return;
        }
      } else if (
        !invoiceVerifier &&
        BuPath.DIST.includes(invoice.bupath) &&
        (invoice?.caastatus === InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED ||
          invoice?.caastatus === InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED ||
          invoice?.caastatus === InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED)
      ) {
        displayError('Please Select Invoice Verifier');
        setSaveInProgress(false);
        setLoader(false);
        return;
      }

      // Invoice Approver
      if (invoiceApprover && invoiceApprover !== '') {
        const entitlementObj = lookup?.invoiceApprovers?.filter(
          obj => obj.userid === invoiceApprover,
        );
        if (entitlementObj.length) {
          payloadData['invoiceVerifiers']?.push({
            approver: invoiceApprover,
            invoiceNmbr: invoice?.invoiceNmbr,
            bupath: purchaseOrder.buPath,
            approverType: 'A',
          });
        } else if (
          BuPath.DIST.includes(invoice.bupath) &&
          (invoice?.caastatus === InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED ||
            invoice?.caastatus ===
              InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED ||
            invoice?.caastatus ===
              InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED)
        ) {
          displayError('Please Select Invoice Verifier');
          setSaveInProgress(false);
          setLoader(false);
          return;
        }
      } else if (
        !invoiceApprover &&
        BuPath.DIST.includes(invoice.bupath) &&
        (invoice?.caastatus === InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED ||
          invoice?.caastatus === InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED ||
          invoice?.caastatus === InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED)
      ) {
        displayError('Please Select Invoice Approver');
        setSaveInProgress(false);
        setLoader(false);
        return;
      }

      if (!invoice?.invoiceNmbr) {
        // @ts-ignore
        await dispatch(
          createInvoice({
            data: [payloadData],
            callback: async data => {
              if (data) {
                if (uploadedFilesArray.length) {
                  await uploadFilesToServer(data[0].invoiceNmbr);
                }
                // @ts-ignore
                dispatch(fetchInvoiceDetails(data[0].invoiceNmbr));
                if (data && data[0].invoiceNmbr) {
                  navigate(`/${ROUTES_STRING.invoices}/${data[0].invoiceNmbr}`);
                }
                displaySuccess('Successfully saved the invoice');
              } else {
                displayError('Create invoice failed');
              }
              setSaveInProgress(false);
              setLoader(false);
            },
          }),
        );
      } else {
        dispatch(
          // @ts-ignore
          updateInvoice({
            invoiceNumber: invoice?.invoiceNmbr,
            data: payloadData,
            callback: async data => {
              if (data) {
                displaySuccess('Successfully updated the invoice');
              } else {
                displayError('Update invoice failed');
              }
              setSaveInProgress(false);
              setLoader(false);
            },
          }),
        );
        await uploadFilesToServer(invoice?.invoiceNmbr);
        // @ts-ignore
        dispatch(fetchInvoiceDetails(invoice?.invoiceNmbr));
        // uploadFilesToServer(invoice?.invoiceNmbr);
      }
    }
  };

  const uploadFilesToServer = async invoiceNmbr => {
    const uploadService = [];
    for (const fileInfo of uploadedFilesArray) {
      uploadService.push(uploadFiles(fileInfo, invoiceNmbr, 'invoices'));
    }
    const fileInfo = await Promise.all(uploadService);
    setUploadedFilesArray([]);
    return fileInfo;
  };

  const validateCostLines = data => {
    for (const item of data) {
      for (const costlineItem of item.costline) {
        // Check if any required property is missing or empty
        if (
          !costlineItem.workorder ||
          !costlineItem.accountNmbr ||
          !costlineItem.glbu ||
          !costlineItem.dept
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const checkEquality = () => {
    let finalTotalCompare = [];

    // Iterate through each activity in the invoice
    for (let z = 0; z < invoice?.invoiceLines?.length; z++) {
      let totalAmount = 0; // Reset totalAmount for each activity

      // Iterate through each costline for the current activity
      for (let i = 0; i < costData?.length; i++) {
        for (let j = 0; j < costData[i]?.costline?.length; j++) {
          if (costData[i]?.costline[j]?.activityNum) {
            if (
              costData[i].costline[j].activityNum ===
              invoice.invoiceLines[z].activitynum
            ) {
              totalAmount += Number(costData[i]?.costline[j]?.linecost);
            }
          } else {
            totalAmount += Number(costData[i]?.costline[j]?.linecost);
          }
        }
      }

      // Push an object containing activity, totalAmount, and quantity to finalTotalCompare
      finalTotalCompare.push({
        activity: invoice?.invoiceLines[z]?.activitynum,
        totalAmount: Number(totalAmount.toFixed(2)),
        quantity: BuPath.DIST.includes(
          invoice?.bupath ? invoice?.bupath : invoice?.purchaseOrder?.bupath,
        )
          ? invoice.invoiceAmount
          : invoice?.invoiceLines[z]?.quantity,
      });
    }

    for (let i = 0; i < finalTotalCompare?.length; i++) {
      // If totalAmount is not equal to quantity, return false
      if (finalTotalCompare[i].totalAmount !== finalTotalCompare[i].quantity) {
        return false;
      }
    }
    // If all objects have totalAmount equal to quantity, return true
    return true;
  };

  const handleInvoiceSubmit = async () => {
    const isValid = validateCostLines(costData);
    const result = checkEquality();
    const entitlementObj = lookup?.userInvoiceEntitlements?.filter(
      obj => obj.userid === invoiceVerifier,
    );
    if (!isValid) {
      displayError('Accounting Details can not be blank.');
    } else if (!result) {
      displayError(
        'The total amount distributed for invoice line does not = original invoice line amount.',
      );
    } else if (
      !invoiceVerifier &&
      BuPath.DIST.includes(invoice.bupath) &&
      (invoice?.caastatus === InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED ||
        invoice?.caastatus === InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED ||
        invoice?.caastatus === InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED)
    ) {
      displayError('Please Select Invoice Verifier');
      setSaveInProgress(false);
    } else if (
      !entitlementObj.length &&
      BuPath.DIST.includes(invoice.bupath) &&
      (invoice?.caastatus === InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED ||
        invoice?.caastatus === InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED ||
        invoice?.caastatus === InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED)
    ) {
      displayError('Please Select Invoice Verifier');
      setSaveInProgress(false);
    } else {
      await handleInvoiceSave();
      let msg = '';
      if (!submitToMaximo) {
        msg = ' This invoice will not be submitted to Maximo.';
      } else {
        msg = ' Are you sure you would like to submit the invoice?';
      }
      if (
        await confirm({
          message: msg,
          title: 'Submit Invoice',
        })
      ) {
        setSaveInProgress(true);
        setLoader(true);
        let caastatus =
          invoice?.exceedsPOCommitted || invoice?.exceedsPOLineThreshold
            ? InvoiceStatus.C_PENDING
            : InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED;
        let patchData = {
          caastatus,
          invoiceStatushistories: [
            {
              caastatus,
              comments: reviewerComment ? reviewerComment : null,
            },
          ],
        };
        if (!vendorInvoiceNumber) {
          patchData = {
            caastatus,
            vendorInvNmbr: invoice?.invoiceNmbr,
            invoiceStatushistories: [
              {
                caastatus,
                comments: reviewerComment ? reviewerComment : null,
              },
            ],
          };
        } else {
          patchData = {
            caastatus,
            vendorInvNmbr: vendorInvoiceNumber,
            invoiceStatushistories: [
              {
                caastatus,
                comments: reviewerComment ? reviewerComment : null,
              },
            ],
          };
        }

        dispatch(
          // @ts-ignore
          updateInvoice({
            invoiceNumber: invoice?.invoiceNmbr,
            data: patchData,
            callback: async data => {
              if (data) {
                displaySuccess('Successfully submitted the invoice');
              } else {
                displayError('Invoice submission failed');
              }
              setLoader(false);
              setSaveInProgress(false);
            },
          }),
        );
      }
    }
  };

  const returnTimesheet = async () => {
    if (
      await confirm({
        message: 'Are you sure do you want to Return Timesheets?',
        title: 'Return Timesheets',
      })
    ) {
      let timesheetIds = [];
      invoice.timesheets.forEach((id, index) => {
        timesheetIds.push(id.recordNmbr);
      });
      dispatch(
        returnTimesheets({
          timesheetIds,
          callback: respData => {
            if (respData?.data?.error) {
              displayError('Unable to return invoice');
            } else {
              displayCompletionFormStatus(
                'Timesheet associated with the Invoice has been returned',
              );
              updateInvoiceStatus(
                InvoiceStatus.C_RETURNED_TO_TIMESHEET,
                'Invoice returned',
              );
            }
          },
        }),
      );
    }
  };

  const updateRetaintionHeld = async () => {
    dispatch(
      // @ts-ignore
      updateInvoice({
        invoiceNumber: invoice?.invoiceNmbr,
        data: {
          retentionWithheld: retentionHeld,
        },
      }),
    );
  };

  const recallInvoice = async () => {
    dispatch(
      // @ts-ignore
      updateInvoice({
        invoiceNumber: invoice?.invoiceNmbr,
        data: {
          caastatus: InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED,
          invoiceStatushistories: [
            {
              caastatus:
                InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED,
              comments: reviewerComment ? reviewerComment : null,
            },
          ],
        },
        callback: async data => {
          if (data) {
            dispatch(fetchInvoiceDetails(invoice?.invoiceNmbr));
          } else {
            displayError('Failed to recall invoice.');
          }
        },
      }),
    );
  };

  const handleSubmitToMaximo = async () => {
    // if (invoice?.invoiceAttachments.length || uploadedFilesArray.length) {
    const isValid = validateCostLines(costData);
    const result = checkEquality();
    if (
      invoice?.purchaseOrder?.caastatus ===
        PurchaseOrderStatus.CLOSED_PENDING_ORDER ||
      invoice?.purchaseOrder?.caastatus ===
        PurchaseOrderStatus.CANCELLED_PENDING_ORDER
    ) {
      displayError(
        'Invoices cannot be submitted for closed and cancelled purchase orders.',
      );
      return;
    }
    if (!isValid) {
      displayError('Accounting Details can not be blank.');
    } else if (!result) {
      displayError(
        'The total amount distributed for invoice line does not = original invoice line amount.',
      );
    } else {
      if (
        await confirm({
          message: ' Are you sure you would like to submit the invoice?',
          title: 'Submit to Maximo',
        })
      ) {
        await updateRetaintionHeld();
        if (uploadedFilesArray.length) {
          await uploadFilesToServer(invoice?.invoiceNmbr);
          dispatch(fetchInvoiceDetails(invoice.invoiceNmbr));
        }
        setSaveInProgress(true);
        setLoader(true);
        if (invoice?.maxInvNmbr) {
          dispatch(
            submitToMaximoAttachDocument({
              invoiceNumber: invoice?.invoiceNmbr,
              callback: async data => {
                if (!data?.error) {
                  dispatch(
                    // @ts-ignore
                    updateInvoice({
                      invoiceNumber: invoice?.invoiceNmbr,
                      data: {
                        caastatus: InvoiceStatus.M_INVOICE_ENTERED,
                        invoiceStatushistories: [
                          {
                            caastatus: InvoiceStatus.M_INVOICE_ENTERED,
                            comments: reviewerComment ? reviewerComment : null,
                          },
                        ],
                      },
                      callback: async data => {
                        dispatch(fetchInvoiceDetails(invoice?.invoiceNmbr));
                      },
                    }),
                  );
                  displaySuccess(
                    'Successfully submitted the invoice to Maximo.',
                  );
                }
                setSaveInProgress(false);
                setLoader(false);
              },
            }),
          );
        } else {
          dispatch(
            // @ts-ignore
            submitToMaximoPSValidation({
              invoiceNumber: invoice?.invoiceNmbr,
              callback: async data => {
                if (!data?.error) {
                  dispatch(
                    sendInvoiceToMaximo({
                      invoiceNumber: invoice?.invoiceNmbr,
                      callback: async data => {
                        if (!data?.error) {
                          dispatch(
                            submitToMaximoAttachDocument({
                              invoiceNumber: invoice?.invoiceNmbr,
                              callback: async data => {
                                if (!data?.error) {
                                  dispatch(
                                    fetchInvoiceDetails(invoice?.invoiceNmbr),
                                  );
                                  displaySuccess(
                                    'Successfully submitted the invoice to Maximo.',
                                  );
                                } else {
                                  // dispatch(
                                  //   // @ts-ignore
                                  //   updateInvoice({
                                  //     invoiceNumber: invoice?.invoiceNmbr,
                                  //     data: {
                                  //       caastatus:
                                  //         InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED,
                                  //     },
                                  //   }),
                                  // );
                                }
                              },
                            }),
                          );
                        } else {
                          dispatch(fetchInvoiceDetails(invoice?.invoiceNmbr));
                        }
                        setSaveInProgress(false);
                        setLoader(false);
                      },
                    }),
                  );
                }
              },
            }),
          );
        }
      }
      // } else {
      //   dispatch(
      //     setCreateInvoiceError({
      //       showError: true,
      //       error: {
      //         error: {
      //           ERRORMESSAGE: [
      //             'An image of the invoice is required to be attached for invoice submission!',
      //           ],
      //         },
      //       },
      //       errorTitle: 'Invalid attachment Error!',
      //     }),
      //   );
      // }
    }
  };

  const updateInvoiceStatus = (caastatus, comments) => {
    dispatch(
      // @ts-ignore
      updateInvoice({
        invoiceNumber: invoice?.invoiceNmbr,
        data: {
          caastatus,
          invoiceStatushistories: [
            {
              caastatus,
              comments: reviewerComment ? reviewerComment : comments,
            },
          ],
        },
        callback: async data => {
          if (caastatus !== InvoiceStatus.C_RETURNED_TO_TIMESHEET) {
            if (data) {
              displaySuccess('Successfully updated the invoice');
            } else {
              displayError('Invoice updation failed');
            }
          }
          setShowReviewerDisagreeModal(false);
        },
      }),
    );
  };

  const cancel = () => {
    navigate(`/${ROUTES_STRING.invoices}`, {
      state: {
        from: 'invoiceDetail',
      },
    });
  };

  const handleDeleteFileFromServer = async (item, index) => {
    const canSave = await confirm({
      message:
        'Are you sure you would like to delete the saved file from Invoice?',
      title: 'Confirm Delete',
    });
    if (!canSave) {
      return;
    }
    const deleted = await deleteFile(
      item.invoiceAttachmentNmbr,
      invoice.invoiceNmbr,
      'invoices',
    );
    invoice?.invoiceAttachments.slice(index, 0);
    // @ts-ignore
    dispatch(fetchInvoiceDetails(invoice.invoiceNmbr));
  };

  const viewFileTimesheet = async fileInfo => {
    await viewFile(
      'timesheets/' +
        fileInfo.recordNmbr +
        '/attachments/' +
        fileInfo.timeAttachmentNmbr,
      fileInfo.fileName,
    );
  };
  const viewFileInvoice = async fileInfo => {
    await viewFile(
      'invoices/' +
        fileInfo.invoiceNmbr +
        '/attachments/' +
        fileInfo.invoiceAttachmentNmbr,
      fileInfo.fileName,
    );
  };
  const viewFile = async (url, filename, file) => {
    await fileViewer({ url, filename, file });
  };

  const handleDownloadInvoicePDF = invoiceNumber => {
    let url = {
      name: `Invoice_${invoiceNumber}`,
      url: `/invoices/export/${invoiceNumber}`,
    };
    excelInvoiceDownload(url);
  };

  return (
    <>
      <div className="aepContainer aepHeader">
        <Row className="justify-content-between">
          <Col sm={6}>Invoice</Col>
          <Col sm={6}>
            <Row className="float-right">
              {![
                InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED_RETURNED,
                InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED,
                InvoiceStatus.C_RETURNED_TO_TIMESHEET,
                InvoiceStatus.C_PENDING,
                InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED,
              ].includes(invoice?.caastatus) &&
                !invoice.maxStatus && (
                  <span
                    className="icons"
                    title="Export Invoice"
                    onClick={() =>
                      handleDownloadInvoicePDF(invoice?.invoiceNmbr)
                    }
                  >
                    <DownloadIcon />
                  </span>
                )}
            </Row>
          </Col>
        </Row>
      </div>
      <div className="aepContainer aepBreadcrumb">
        <Row>
          <Col sm={6} style={{ display: 'flex' }}>
            <div onClick={cancel} className="backArrow" title="Back">
              <BackArrowIcon />
            </div>
            Contract No: {invoice?.contractNmbr} | PO:
            {invoice?.purchaseOrder?.ponum}
          </Col>
          <Col sm={6}>
            <Row className="float-right">
              <div className="statusIcon"></div>Status:&nbsp;
              {InvoiceStatusDisplayValue[invoice?.caastatus]}
            </Row>
          </Col>
        </Row>
      </div>
      <div className="aepContainer invoiceDetails">
        <div className="innerContainer">
          <Form>
            <Row>
              <Col sm={3}>
                {invoice.vendorInvNmbr !== undefined ? (
                  <Form.Group controlId="form.vendorInvoiceNumber">
                    <Form.Label>Vendor Invoice Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="vendorInvoiceNumber"
                      disabled={
                        invoice?.caastatus !==
                          InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED &&
                        invoice?.caastatus !==
                          InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED &&
                        invoice?.caastatus !==
                          InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED &&
                        invoice?.caastatus !==
                          InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED_RETURNED
                      }
                      value={vendorInvoiceNumber}
                      onChange={e => setVendorInvoiceNumber(e.target.value)}
                    />
                  </Form.Group>
                ) : (
                  <></>
                )}
              </Col>
              {(invoice?.caastatus ===
                InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED ||
                invoice?.caastatus ===
                  InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED ||
                invoice?.caastatus ===
                  InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED) && (
                <>
                  <Col sm={3}>
                    <Form.Group>
                      <Form.Label>Invoice Verifier</Form.Label>
                      <Controller
                        control={control}
                        name="timesheetApprover"
                        render={({ onChange, value, name, ref }) => (
                          <Form.Select
                            value={
                              invoiceVerifier != ''
                                ? invoiceVerifier
                                : invoice.invoiceVerifier
                            }
                            onChange={e => setInvoiceVerifier(e.target.value)}
                            disabled={
                              ![
                                InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED,
                                InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED,
                                InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED,
                              ].includes(invoice?.caastatus)
                            }
                          >
                            <option value="">Select</option>
                            {defaultInvocieVerifier?.map(
                              (userEntitlement, eIndex) => (
                                <option
                                  key={eIndex}
                                  value={userEntitlement.userid}
                                >
                                  {userEntitlement.name}
                                </option>
                              ),
                            )}
                          </Form.Select>
                        )}
                      />
                    </Form.Group>
                  </Col>

                  {BuPath.DIST.includes(invoice.bupath) && (
                    <Col sm={3}>
                      <Form.Group>
                        <Form.Label>Invoice Approver</Form.Label>
                        <Controller
                          control={control}
                          name="invoiceApprover"
                          render={({ onChange, value, name, ref }) => (
                            <Form.Select
                              value={
                                invoiceApprover != ''
                                  ? invoiceApprover
                                  : invoice.invoiceApprover
                              }
                              onChange={e => setInvoiceApprover(e.target.value)}
                              disabled={
                                ![
                                  InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED,
                                  InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED,
                                  InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED,
                                ].includes(invoice?.caastatus)
                              }
                            >
                              <option value="">Select</option>
                              {lookup?.invoiceApprovers?.map(
                                (userEntitlement, eIndex) => (
                                  <option
                                    key={eIndex}
                                    value={userEntitlement.userid}
                                  >
                                    {userEntitlement.name}
                                  </option>
                                ),
                              )}
                            </Form.Select>
                          )}
                        />
                      </Form.Group>
                    </Col>
                  )}
                </>
              )}
              {invoice?.invoiceNmbr ? (
                <Col sm={3}>
                  <Form.Group controlId="form.caaInvoiceNumber">
                    <Form.Label>CAA Invoice Number</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={invoice?.invoiceNmbr}
                      disabled
                    />
                  </Form.Group>
                </Col>
              ) : (
                <></>
              )}
              <Col sm={3}>
                {invoice.maxInvNmbr ? (
                  <Form.Group>
                    <Form.Label>Maximo Invoice Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="maximoInvoiceNumber"
                      disabled={true}
                      defaultValue={invoice.maxInvNmbr}
                    />
                  </Form.Group>
                ) : (
                  <></>
                )}
              </Col>
              <Col sm={3}>
                {IsAllowedACL(user, ModuleACL.INVOICE, 'can_edit_invoice') && (
                  <Fragment>
                    {/* {actions[InvoiceAction.editInvoice] && (
                  <div className="editIcon">
                    <EditIcon />
                  </div>
                )} */}
                  </Fragment>
                )}
              </Col>
            </Row>

            <Divider style={{ margin: '0 20px 20px 0' }} />

            <Row>
              {invoice?.weekStartDate ? (
                <Col sm={3}>
                  <Form.Group controlId="form.startDate">
                    <Form.Label>Invoice Start Date</Form.Label>
                    <Form.Control
                      type="text"
                      value={moment(
                        invoice?.weekStartDate,
                        'YYYY-MM-DD',
                      ).format('MM/DD/YYYY')}
                      disabled
                    />
                  </Form.Group>
                </Col>
              ) : null}
              <Col sm={3}>
                <Form.Group controlId="form.endDate">
                  <Form.Label>Invoice End Date</Form.Label>
                  {invoice?.weekEndDate ? (
                    <Form.Control
                      type="text"
                      value={moment(invoice?.weekEndDate, 'YYYY-MM-DD').format(
                        'MM/DD/YYYY',
                      )}
                      disabled
                    />
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group controlId="form.endDate">
                  <Form.Label>Invoice Date</Form.Label>
                  {invoice?.weekEndDate || invoice?.invoiceDate ? (
                    <Form.Control
                      type="text"
                      value={
                        BuPath.DIST.includes(invoice.bupath)
                          ? moment(invoice?.weekEndDate, 'YYYY-MM-DD').format(
                              'MM/DD/YYYY',
                            )
                          : invoice?.invoiceDate &&
                            moment(invoice?.invoiceDate, 'YYYY-MM-DD').format(
                              'MM/DD/YYYY',
                            )
                      }
                      disabled
                    />
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group controlId="form.endDate">
                  <Form.Label>Business Unit</Form.Label>
                  <Form.Control
                    type="text"
                    name="businessUnit"
                    value={invoice?.bupath}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Divider style={{ margin: '0 20px 20px 0' }} />
            <Row>
              <Col sm={3}>
                <Form.Group controlId="form.vendorContactName">
                  <Form.Label>Vendor Contact Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      invoice?.purchaseOrder?.remitcompanyname
                        ? invoice?.purchaseOrder?.remitcompanyname
                        : ''
                    }
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group controlId="form.telephoneNumber">
                  <Form.Label>Telephone Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={invoice?.purchaseOrder?.remitcompanyphone}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group controlId="form.remitAddress">
                  <Form.Label>Remit Address</Form.Label>
                  <Form.Control
                    type="text"
                    style={{ fontSize: '11.5px' }}
                    value={[
                      invoice?.purchaseOrder?.remitcompanyaddress1,
                      invoice?.purchaseOrder?.remitcompanyaddress2,
                      invoice?.purchaseOrder?.remitcompanyaddress3,
                      invoice?.purchaseOrder?.remitcompanyaddress4,
                      invoice?.purchaseOrder?.remitcompanyaddress5,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            {IsAllowedACL(
              user,
              ModuleACL.INVOICE,
              'can_by_pass_retention_held_invoice',
            ) &&
              invoice?.caastatus ===
                InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED && (
                <Fragment>
                  <div className="checkboxDiv">
                    <input
                      type="checkbox"
                      checked={retentionHeld}
                      onChange={e => setRetentionHeld(!retentionHeld)}
                      style={{ width: '20px', height: '20px' }}
                    />
                    <span style={{ fontSize: '18px', marginLeft: '12px' }}>
                      By Pass Retention Held
                    </span>
                  </div>
                </Fragment>
              )}
          </Form>
        </div>
      </div>

      {/* if invoice amount is not empty then only show this accounting components */}

      <div className="aepContainer">
        <Accounting
          handleInvoiceSave={handleInvoiceSave}
          saveInProgress={saveInProgress}
          invoiceVerifier={invoiceVerifier}
          invoiceApprover={invoiceApprover}
        />
      </div>

      <div className="aepContainer">
        <Tabs
          id="poPage"
          // activeKey={key}
          onSelect={k =>
            setKey(
              // @ts-ignore
              k,
            )
          }
        >
          {tabs.map((tab, index) =>
            invoice[tab.key] && invoice[tab.key].length > 0 ? (
              <Tab
                eventKey={tab.key}
                title={tab.value}
                key={index}
                tabClassName="tab-item"
              >
                <div className="tableContainer">
                  {/* <div className="search">
                    <div style={{ display: 'flex' }}>
                      <Form.Group className="mb-3" controlId="searchPO">
                        <Form.Control type="text" placeholder="Search" />
                      </Form.Group>
                      <div className="searchIcon">
                        <SearchIcon />
                      </div>
                    </div>
                  </div> */}
                  <Table
                    hover
                    responsive
                    striped
                    bordered={false}
                    className="invoiceActivityTable"
                  >
                    <thead>
                      <tr>
                        {headers[tab.key].map((header, idx) => (
                          <th
                            key={idx}
                            className={
                              [
                                'Labor Hours',
                                'Equipment Hours',
                                'Total Lumpsum',
                                'Reimbursable Quantity',
                                'Uptask Quantity',
                                'Amount',
                              ].indexOf(header) > -1
                                ? 'text-end'
                                : ''
                            }
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {invoice[tab.key]?.map((row, indx) => {
                        if (tab.key === 'activities') {
                          return (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row?.activitynum}</td>
                              <td>{row?.activityDescription}</td>
                              <td className="text-end">{row?.laborHours}</td>
                              <td className="text-end">
                                {row?.equipmentHours}
                              </td>
                              <td className="text-end">{row?.expense}</td>
                              <td className="text-end">{row?.reimbQuantity}</td>
                              <td className="text-end">
                                {row?.uptaskQuantity}
                              </td>
                              <td className="text-end">
                                {currencyFormat(row?.amount)}
                              </td>
                            </tr>
                          );
                        } else if (tab.key === SERVICE_TYPES.LABOR) {
                          return (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row?.laborCode}</td>
                              <td>{row?.employeeName}</td>
                              <td>{getClassificationLabel(row?.laborType)}</td>
                              {/* <td>{row?.laborShift}</td> */}
                              <td>{row?.laborRate}</td>
                              <td>{row?.laborHours}</td>
                              <td>{row?.laborUnits}</td>

                              <td className="text-end">
                                {currencyFormat(row?.lineTotal)}
                              </td>
                            </tr>
                          );
                        } else if (tab.key === SERVICE_TYPES.EQUIPMENT) {
                          return (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row?.equipDescription}</td>
                              <td>{getClassificationLabel(row?.equipType)}</td>

                              {/* <td>{row?.equipShift}</td> */}
                              <td>{row?.equipRate}</td>
                              <td>
                                {row.equipType === 'opHours'
                                  ? row.equipHours
                                  : ''}
                              </td>
                              <td>
                                {row.equipType === 'equipmentRentalUnits'
                                  ? row.equipHours
                                  : row.equipUnits}
                              </td>
                              <td className="text-end">
                                {currencyFormat(row?.lineTotal)}
                              </td>
                            </tr>
                          );
                        } else if (
                          tab.key === SERVICE_TYPES.REIMBURSABLE_MARKUP
                        ) {
                          return (
                            <tr key={indx} className="tRow table-hover-row ">
                              <td>{row?.reimbCode}</td>
                              {/* <td>{row?.reimbShift}</td> */}
                              <td>{row?.reimbRate}</td>
                              <td>{row?.reimbUnits}</td>
                              <td>{row.basepricemarkup}</td>
                              <td>{row.deliverymarkup}</td>
                              <td>{row.miscellaneousmarkup}</td>
                              <td className="text-end">
                                {currencyFormat(row?.lineTotal)}
                              </td>
                            </tr>
                          );
                        } else if (tab.key === SERVICE_TYPES.UNIT_PRICE_TASK) {
                          return (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row?.uptaskCode}</td>
                              {/* <td>{row?.upTaskShift}</td> */}
                              <td>{row?.uptaskRate}</td>
                              <td>{row?.uptaskUnits}</td>
                              <td className="text-end">
                                {currencyFormat(row?.lineTotal)}
                              </td>
                            </tr>
                          );
                        } else if (tab.key === SERVICE_TYPES.LUMPSUM) {
                          return (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row?.activitynum}</td>
                              <td>{row?.activityDescription}</td>
                              <td>{row?.lumpsumRate}</td>
                              <td>{row?.lumpsumUnits}</td>
                              <td className="text-end">
                                {currencyFormat(row?.lineTotal)}
                              </td>
                            </tr>
                          );
                        } else if (tab.key === SERVICE_TYPES.CU) {
                          return (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row?.cuGroupCode}</td>
                              <td>{row?.cuGroupRate}</td>
                              <td>{row?.quantity}</td>
                              <td>{row?.action}</td>
                              <td className="text-end">
                                {currencyFormat(row?.lineTotal)}
                              </td>
                            </tr>
                          );
                        } else if (tab.key === 'invAdj') {
                          return (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row?.name}</td>
                              <td className="text-end">
                                {currencyFormat(row?.value)}
                              </td>
                            </tr>
                          );
                        }
                      })}
                      {invoice[tab.key]?.map(
                        (
                          // @ts-ignore
                          row,
                          index,
                        ) => {
                          if (tab.key === 'activities' && index === 0) {
                            return (
                              <tr key={index} className="tRow table-hover-row">
                                <th>Grand Total</th>
                                <th></th>
                                <th className="text-end">
                                  {invoice?.totalActivityLabor}
                                </th>
                                <th className="text-end">
                                  {invoice?.totalActivityEqp}
                                </th>
                                <th className="text-end">
                                  {invoice?.totalActivityExpense}
                                </th>
                                <th className="text-end">
                                  {invoice?.totalActivityReimb}
                                </th>
                                <th className="text-end">
                                  {invoice?.totalActivityUptask}
                                </th>

                                {/* <th></th> */}
                                <th className="text-end">
                                  {currencyFormat(invoice?.totalActivity)}
                                </th>
                              </tr>
                            );
                          } else if (
                            tab.key === SERVICE_TYPES.LABOR &&
                            index === 0
                          ) {
                            return (
                              <tr key={index} className="tRow table-hover-row">
                                <th>Total Labor Cost</th>
                                <th colSpan={10} className="text-end">
                                  {currencyFormat(invoice?.totalLaborAmount)}
                                </th>
                              </tr>
                            );
                          } else if (
                            tab.key === SERVICE_TYPES.EQUIPMENT &&
                            index === 0
                          ) {
                            return (
                              <tr key={index} className="tRow table-hover-row">
                                <th>Total Equipment Cost</th>
                                <th colSpan={10} className="text-end">
                                  {currencyFormat(
                                    invoice?.totalEquipmentAmount,
                                  )}
                                </th>
                              </tr>
                            );
                          } else if (
                            tab.key === SERVICE_TYPES.REIMBURSABLE_MARKUP &&
                            index === 0
                          ) {
                            return (
                              <tr key={index} className="tRow table-hover-row">
                                <th>Total Reimbursable Cost</th>
                                <th colSpan={10} className="text-end">
                                  {currencyFormat(invoice?.totalReimbursable)}
                                </th>
                              </tr>
                            );
                          } else if (
                            tab.key === SERVICE_TYPES.UNIT_PRICE_TASK &&
                            index === 0
                          ) {
                            return (
                              <tr key={index} className="tRow table-hover-row">
                                <th>Total Unit Price Cost</th>
                                <th colSpan={10} className="text-end">
                                  {currencyFormat(invoice?.totalUpTask)}
                                </th>
                              </tr>
                            );
                          } else if (
                            tab.key === SERVICE_TYPES.CU &&
                            index === 0
                          ) {
                            return (
                              <tr key={index} className="tRow table-hover-row">
                                <th>Total Cu</th>
                                <th colSpan={10} className="text-end">
                                  {currencyFormat(invoice?.totalCus)}
                                </th>
                              </tr>
                            );
                          }
                        },
                      )}
                    </tbody>
                  </Table>
                </div>
              </Tab>
            ) : null,
          )}

          {Math.ceil(invoice?.fuelAdjCost) > 0 && (
            <Tab
              eventKey={'fuelAdj'}
              title={'Fuel Adjustment'}
              key={'fuelAdjustment'}
              tabClassName="tab-item"
            >
              <div className="tableContainer">
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr key={'faKey'} className="tRow table-hover-row">
                      <th>Fuel Adjustment Cost</th>

                      <th>{currencyFormat(invoice?.fuelAdjCost)}</th>
                    </tr>
                  </thead>
                </Table>
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
      {invoice.invoicePmthistories &&
        !!invoice.invoicePmthistories.length &&
        (invoice?.maxStatus === 'PAID' || invoice?.maxStatus === 'PAID-AP') && (
          <div className="aepContainer">
            <div className="timesheetHeader">Payment History </div>
            <div className="innerContainer">
              {invoice.invoicePmthistories.map((paymentHistory, index) => {
                return (
                  <Form>
                    <Row>
                      <Col sm={3}>
                        <Form.Group>
                          <Form.Label>Status Date</Form.Label>
                          <DatePicker
                            selected={new Date(paymentHistory.pmtdate)}
                            disabled
                            onChange={date => setpaymentHistoryDate(date)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group>
                          <Form.Label>Check Status</Form.Label>
                          <Form.Control
                            defaultValue={paymentHistory.status}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group>
                          <Form.Label>Check Number</Form.Label>
                          <Form.Control
                            defaultValue={paymentHistory.checknum}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group>
                          <Form.Label>Paid Amount</Form.Label>
                          <Form.Control
                            defaultValue={paymentHistory.paidamt}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                );
              })}
            </div>
          </div>
        )}
      <div className="aepContainer">
        <div className="timesheetHeader">Timesheets</div>
        {invoice?.timesheets?.map((timesheet, index) => {
          return (
            <div
              key={index}
              className="timesheetContainer"
              style={{ marginTop: index === 0 ? '24px' : '' }}
            >
              <div className="timesheetDiv">
                <div>
                  <Form.Control
                    name={'ts' + timesheet?.recordNmbr}
                    type="text"
                    value={timesheet?.recordNmbr}
                    disabled={true}
                  />
                </div>

                <div className="hideTimesheets">
                  <div
                    onClick={
                      // @ts-ignore
                      e => {}
                    }
                  >
                    {/* <CloseButton /> */}
                  </div>

                  <div
                    onClick={
                      // @ts-ignore
                      e => hideTimesheetDiv(index)
                    }
                  >
                    <div
                      className="tNum"
                      id={`showMaxDiv${index}`}
                      style={{ display: 'none' }}
                      title="Maximize"
                    >
                      <MaximizeIcon />
                    </div>
                    <div
                      className="tNum"
                      id={`showMinDiv${index}`}
                      title="Minimize"
                    >
                      <MinimizeIcon />
                    </div>
                  </div>
                </div>
              </div>

              <div id={`timesheet${index}`} className="timesheetContent">
                {BuPath.DIST.includes(timesheet.bupath) ? (
                  <Row>
                    <Col sm={3}>
                      <Form.Label>Timesheet Week End Date</Form.Label>
                      <Form.Control
                        type="textarea"
                        value={moment(
                          timesheet?.weekEndDate,
                          'YYYY-MM-DD',
                        ).format('MM/DD/YYYY')}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm={5}>
                      <Form.Label>Timesheet Start Date</Form.Label>
                      <Form.Control
                        type="textarea"
                        value={moment(
                          timesheet?.workStartDate,
                          'YYYY-MM-DD',
                        ).format('MM/DD/YYYY')}
                        disabled={true}
                      />
                    </Col>
                    <Col sm={7}>
                      <Form.Label>Timesheet End Date</Form.Label>
                      <Form.Control
                        type="textarea"
                        value={moment(
                          timesheet?.workEndDate,
                          'YYYY-MM-DD',
                        ).format('MM/DD/YYYY')}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                )}
                <br />

                {invoice?.carryCommentsTimesheet || carryCommentsTimesheet ? (
                  <Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label className="uvlLabel">Comments</Form.Label>
                        <Form.Control
                          className="w-100"
                          type="textarea"
                          defaultValue={timesheet?.comments}
                          disabled={true}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="aepContainer btnArea" style={{ backgroundColor: 'none' }}>
        {user.userType === UserType.AEP &&
          invoice?.caastatus === InvoiceStatus.C_PENDING &&
          IsAllowedACL(user, ModuleACL.INVOICE, 'can_agree_invoice') &&
          IsAllowedACL(user, ModuleACL.INVOICE, 'can_disagree_invoice') && (
            <Button variant="primary" onClick={recallInvoice}>
              Recall
            </Button>
          )}
      </div>

      <div className="aepContainer">
        <div className="addBtn addClassification download-icon-setup btnWrap">
          <span style={{ float: 'left' }}>
            {IsAllowedACL(user, ModuleACL.INVOICE, 'can_edit_file_invoice') && (
              <Fragment>
                <Button
                  active={false}
                  variant="link"
                  onClick={e => setShowAttachment(true)}
                >
                  <AttachmentsIcon /> Invoice Attachment{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Button>
              </Fragment>
            )}
          </span>

          {uploadedFilesArray?.map((item, index) => (
            <>
              <span style={{ float: 'left' }} title="PDF">
                {IsAllowedACL(
                  user,
                  ModuleACL.INVOICE,
                  'can_view_file_invoice',
                ) ||
                  (IsAllowedACL(
                    user,
                    ModuleACL.INVOICE,
                    'can_remove_file_invoice',
                  ) && (
                    <Fragment>
                      <PDFIcon />
                    </Fragment>
                  ))}

                {IsAllowedACL(
                  user,
                  ModuleACL.INVOICE,
                  'can_view_file_invoice',
                ) && (
                  <Fragment>
                    <Button
                      active={false}
                      variant="link"
                      className="pdfBtn"
                      onClick={() => viewFile(null, item.name, item)}
                    >
                      {item.name ? item?.name : item?.files?.name}
                    </Button>
                  </Fragment>
                )}

                {IsAllowedACL(
                  user,
                  ModuleACL.INVOICE,
                  'can_remove_file_invoice',
                ) && (
                  <Fragment>
                    <Button
                      active={false}
                      variant="link"
                      title="Close"
                      className="removeFile"
                      onClick={() => handleDeleteFileFromArray(index)}
                    >
                      <CloseIcon />
                    </Button>
                  </Fragment>
                )}
              </span>
            </>
          ))}
          {invoice.invoiceNmbr && invoice?.invoiceAttachments.length > 0
            ? invoice?.invoiceAttachments.map((item, index) => (
                <>
                  {!item?.description?.includes('Consolidated') && (
                    <span>
                      <Fragment>
                        <PDFIcon />
                      </Fragment>
                      {IsAllowedACL(
                        user,
                        ModuleACL.INVOICE,
                        'can_view_file_invoice',
                      ) && (
                        <Fragment>
                          <Button
                            active={false}
                            variant="link"
                            className="pdfBtn"
                            onClick={() => viewFileInvoice(item)}
                          >
                            {item.fileName}
                          </Button>
                        </Fragment>
                      )}

                      {IsAllowedACL(
                        user,
                        ModuleACL.INVOICE,
                        'can_remove_file_invoice',
                      ) && (
                        <Fragment>
                          <Button
                            active={false}
                            variant="link"
                            title="Close"
                            className="removeFile"
                            onClick={() =>
                              handleDeleteFileFromServer(item, index)
                            }
                          >
                            <CloseIcon />
                          </Button>
                        </Fragment>
                      )}
                    </span>
                  )}
                </>
              ))
            : invoice?.timesheets.map((item, index) =>
                item.timesheetAttachments.map((itm, idx) => (
                  <>
                    <span>
                      <Fragment>
                        <PDFIcon />
                      </Fragment>
                      {IsAllowedACL(
                        user,
                        ModuleACL.INVOICE,
                        'can_view_file_invoice',
                      ) && (
                        <Fragment>
                          <Button
                            active={false}
                            variant="link"
                            className="pdfBtn"
                            onClick={() => viewFileTimesheet(itm)}
                          >
                            {itm.fileName}
                          </Button>
                        </Fragment>
                      )}
                    </span>
                  </>
                )),
              )}
        </div>
      </div>

      {invoice?.maxInvNmbr && (
        <div className="aepContainer">
          <div className="addBtn addClassification download-icon-setup btnWrap">
            <span style={{ float: 'left' }}>
              {IsAllowedACL(
                user,
                ModuleACL.INVOICE,
                'can_edit_file_invoice',
              ) && (
                <Fragment>
                  <Button active={false} variant="link">
                    <AttachmentsIcon /> Consolidated Invoice Attachment
                  </Button>
                </Fragment>
              )}
            </span>

            {invoice?.invoiceAttachments.map((item, index) => (
              <>
                {item?.description?.includes('Consolidated') && (
                  <span>
                    {IsAllowedACL(
                      user,
                      ModuleACL.INVOICE,
                      'can_view_file_invoice',
                    ) ||
                      (IsAllowedACL(
                        user,
                        ModuleACL.INVOICE,
                        'can_remove_file_invoice',
                      ) && (
                        <Fragment>
                          <PDFIcon />
                        </Fragment>
                      ))}

                    {IsAllowedACL(
                      user,
                      ModuleACL.INVOICE,
                      'can_view_file_invoice',
                    ) && (
                      <Fragment>
                        <Button
                          active={false}
                          variant="link"
                          className="pdfBtn"
                          onClick={() => viewFileInvoice(item)}
                        >
                          {item.fileName}
                        </Button>
                      </Fragment>
                    )}
                  </span>
                )}
              </>
            ))}
          </div>
        </div>
      )}

      {invoice?.invoiceStatushistories?.map((obj, index) => (
        <div className="aepContainer">
          <Form.Group controlId="form.reviewerComment">
            {index === 0 && (
              <Form.Label
                className="reviewerCommentLabel"
                style={{ marginLeft: '0', marginBottom: '12px' }}
              >
                Reviewers Comments
              </Form.Label>
            )}
            {!isEmptyVal(obj.comments) && (
              <Controller
                control={control}
                name="reviewerComments"
                defaultValue=""
                disabled={true}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Form.Control
                    style={{
                      width: '100%',
                      marginBottom: '12px',
                      backgroundColor: '#f3f3f3',
                    }}
                    value={obj.comments}
                    ref={ref}
                    type="text"
                  />
                )}
              />
            )}
          </Form.Group>
        </div>
      ))}
      <div className="invoiceCheckDiv">
        {!invoice?.exceedsPOCommitted ? (
          <div>
            <CheckIcon width={20} height={20} />
            <span>Total does not exceed PO Committed</span>
          </div>
        ) : (
          <div>
            <CloseIcon width={20} height={20} />
            <span>Total exceed PO Committed</span>
          </div>
        )}
        {!invoice?.exceedsPOLineThreshold ? (
          <div>
            <CheckIcon width={20} height={20} />
            <span>Total does not exceed PO Line Threshold</span>
          </div>
        ) : (
          <div>
            <CloseIcon width={20} height={20} />
            <span>Total exceed PO Line Threshold</span>
          </div>
        )}
      </div>

      {IsAllowedACL(user, ModuleACL.INVOICE, 'can_submit_invoice_maximo') &&
        [
          InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED,
          InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED,
          InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED,
        ].includes(invoice?.caastatus) && (
          <Fragment>
            <div className="checkboxDiv" style={{ marginLeft: '24px' }}>
              <input
                type="checkbox"
                checked={submitToMaximo}
                // @ts-ignore
                onChange={e => setSubmitToMaximo(e.target.checked)}
                style={{ width: '20px', height: '20px' }}
                disabled={
                  // This piece of code is blocking the invoice submission.
                  // Was there any requirement for this?
                  /*invoice?.caastatus ===
                    InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED ||*/
                  invoice.maxStatus
                }
              />
              <span style={{ fontSize: '18px', marginLeft: '12px' }}>
                Submit Invoice to Maximo
              </span>
            </div>
          </Fragment>
        )}

      {![
        InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED,
        //InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED_RETURNED,
        // InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED,
        InvoiceStatus.C_RETURNED_TO_TIMESHEET,
        InvoiceStatus.C_PENDING,
        // This piece of code is blocking the invoice submission.
        // Was there any requirement for this?
        /*
        InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED
        */
      ].includes(invoice?.caastatus) &&
        (!invoice.maxStatus ||
          ['CANCEL', 'REVERSED'].includes(invoice.maxStatus)) && (
          <div className="aepContainer btnArea">
            {IsAllowedACL(
              user,
              ModuleACL.INVOICE,
              'can_submit_new_invoice',
            ) && (
              <Button
                variant="primary"
                onClick={handleInvoiceSubmit}
                disabled={
                  (invoice?.invoiceNmbr ? false : true) ||
                  !invoice?.invoiceAttachments ||
                  saveInProgress
                }
                style={{ marginLeft: 'auto', padding: '10px 20px' }}
              >
                Submit
              </Button>
            )}

            {IsAllowedACL(user, ModuleACL.INVOICE, 'can_edit_invoice') && (
              <Button
                variant="secondary"
                onClick={handleInvoiceSave}
                disabled={saveInProgress}
                style={{ marginLeft: '20px', padding: '3px 20px' }}
              >
                Save
              </Button>
            )}

            {IsAllowedACL(user, ModuleACL.INVOICE, 'can_edit_invoice') && (
              <Fragment>
                {invoice?.invoiceNmbr ? (
                  <></>
                ) : (
                  <Button
                    variant="secondary"
                    onClick={cancel}
                    style={{ padding: '3px 20px' }}
                  >
                    Cancel
                  </Button>
                )}
              </Fragment>
            )}

            {((invoice?.caastatus ===
              InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED_RETURNED &&
              IsAllowedACL(
                user,
                ModuleACL.INVOICE,
                'can_submit_new_invoice',
              )) ||
              ([
                InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED,
                InvoiceStatus.C_M_REVERSED_INVOICE_RETURNED_FROM_PEOPLESOFT,
              ].includes(invoice?.caastatus) &&
                IsAllowedACL(
                  user,
                  ModuleACL.INVOICE,
                  'can_submit_new_invoice',
                ))) && (
              <Button
                variant="primary"
                onClick={() => returnTimesheet()}
                style={{ marginRight: '0' }}
              >
                Return Timesheet
              </Button>
            )}
          </div>
        )}

      {invoice?.caastatus ===
        InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED && (
        <div className="btnArea">
          {IsAllowedACL(user, ModuleACL.INVOICE, 'can_agree_invoice') && (
            <Button
              variant="primary"
              onClick={() => handleSubmitToMaximo()}
              disabled={submitToMaximo && !saveInProgress ? false : true}
              style={{ marginLeft: 'auto', marginRight: '0' }}
            >
              Agree - Submit to Maximo
            </Button>
          )}

          {IsAllowedACL(user, ModuleACL.INVOICE, 'can_disagree_invoice') && (
            <Button
              variant="secondary"
              onClick={e => setShowReviewerDisagreeModal(true)}
              style={{ marginRight: '24px' }}
            >
              Disagree
            </Button>
          )}
        </div>
      )}

      <Modal
        show={showAttachment}
        onHide={() => setShowAttachment(false)}
        size="md"
        dialogClassName="invoicePreviewDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={false}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              height: '26px',
            }}
          >
            <div className="backArrow">
              <BackArrowIcon styles={{ marginTop: '-4px' }} title="Back" />
            </div>
            Upload Documents
            <div
              style={{ marginLeft: 'auto', cursor: 'pointer' }}
              onClick={e => setShowAttachment(false)}
              title="Close"
            >
              <CloseIcon />
            </div>
          </div>
        </Modal.Header>
        <Divider />
        <Modal.Body className="invoicePreviewModalBody">
          <div className="uploadSection">
            <DragAndDrop
              handleFileUpload={handleFileUpload}
              uploadedFilesArray={uploadedFilesArray}
              handleDeleteFileFromArray={handleDeleteFileFromArray}
              setFileUploadInprogress={setFileUploadInprogress}
              allowedMimeTypes={['application/pdf']}
            />
          </div>
          <div className="btnArea">
            {IsAllowedACL(user, ModuleACL.INVOICE, 'can_edit_file_invoice') && (
              <Fragment>
                <Button
                  variant="primary"
                  disabled={
                    uploadedFilesArray.length < 0 || fileUploadInprogress
                  }
                  onClick={handleCancelUpload}
                >
                  Upload
                </Button>
                <Button variant="secondary" onClick={handleCancelUpload}>
                  Cancel
                </Button>
              </Fragment>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAttachmentPDF}
        onHide={() => setShowAttachmentPDF(false)}
        size="lg"
        dialogClassName="invoicePreviewDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={false}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              height: '26px',
            }}
          >
            <div className="backArrow">
              <BackArrowIcon styles={{ marginTop: '-4px' }} title="Back" />
            </div>
            PDF View
            <div
              style={{ marginLeft: 'auto', cursor: 'pointer' }}
              onClick={() => setShowAttachmentPDF(false)}
              title="Close"
            >
              <CloseIcon />
            </div>
          </div>
        </Modal.Header>
        <Divider />
        <Modal.Body className="invoicePreviewModalBody">
          <InvoiceViewAttachment file={pdfDocumentUrl} />
        </Modal.Body>
      </Modal>
      <ReviewerModal
        control={control}
        showModal={showReviewerDisagreeModal}
        setShowModal={setShowReviewerDisagreeModal}
        setReviewerComment={setReviewerComment}
        handleContinueClick={() =>
          updateInvoiceStatus(
            InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED_RETURNED,
          )
        }
        title="Cancel Invoice?"
      />
      <Modal
        show={maximoInvoiceError}
        onHide={hideMaximoErrorModel}
        size="lg"
        dialogClassName="invoicePreviewDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={false}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              height: '26px',
            }}
          >
            <div className="errorIcon">
              <ErrorIcon />
            </div>
            {maxErrorTitle}
            <div
              style={{ marginLeft: 'auto', cursor: 'pointer' }}
              onClick={hideMaximoErrorModel}
              title="Close"
            >
              <CloseIcon />
            </div>
          </div>
        </Modal.Header>
        <Divider />
        <Modal.Body className="invoicePreviewModalBody">
          <div style={{ fontSize: '20px', marginBottom: '24px' }}>
            {_.isArray(maximoErrors)
              ? maximoErrors?.length
                ? maximoErrors.map((error, index) => <div>{error}</div>)
                : 'PeopleSoft Validation is currently down.'
              : 'Please try again later.'}
          </div>
          <div className="btnArea">
            <Fragment>
              <Button variant="secondary" onClick={hideMaximoErrorModel}>
                Okay
              </Button>
            </Fragment>
          </div>
        </Modal.Body>
      </Modal>
      <ClipLoader
        color="#009cde"
        loading={invoiceLoading || loader}
        cssOverride={{
          top: '50vh',
          left: '50vw',
          position: 'absolute',
          zIndex: 1,
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};
export { InvoiceDetails };
