import { CheckIcon } from 'assets/images/CheckIcon/CheckIcon';
import { PDFIcon } from 'assets/images/PDFIcon/PDFIcon';
import { UploadPDFIcon } from 'assets/images/UploadPDFIcon/UploadPDFIcon';
import React, { useRef, useState } from 'react';
import { Alert, Col, ProgressBar, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import './DragAndDrop.scss';

const DragAndDrop = ({
  setFileUploadInprogress,
  uploadedFilesArray,
  handleFileUpload,
  handleDeleteFileFromArray,
  allowedMimeTypes = [],
}) => {
  const [count, setCount] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  let tempIndex;
  const loader = (counter, index) => {
    if (counter === 101) {
      setFileUploadInprogress(false);
      tempIndex = null;
      return;
    }
    if (!tempIndex) {
      tempIndex = index;
    }
    let arr = [...count];
    if (!index) {
      arr.push(counter);
    }
    arr[tempIndex] = counter;
    setCount(arr);
    setTimeout(loader, 10, ++counter);
  };

  const handleUploadFile = e => {
    e.preventDefault();
    e.stopPropagation();

    let target = e.target;
    if (e?.dataTransfer?.files?.length) {
      target = e.dataTransfer;
    }
    setFileUploadInprogress(true);

    setErrorMessage('');
    if (uploadedFilesArray.length < 12) {
      if (target?.files?.length) {
        /*Maximum allowed size in bytes 15MB */
        const maxAllowedSize = 60 * 1024 * 1024;
        let canUploadFiles = [];
        for (let i = 0; i < target?.files?.length; ++i) {
          const fileObject = target.files[i];
          if (fileObject.name.length > 150) {
            setErrorMessage('Filename should not be more than 150 chars.');
            setTimeout(() => {
              setErrorMessage('');
            }, 30000);
          } else if (fileObject.size > maxAllowedSize) {
            setErrorMessage('Maximum allowed size is 60 MB');
            setTimeout(() => {
              setErrorMessage('');
            }, 30000);
          } else if (
            allowedMimeTypes.length &&
            !allowedMimeTypes.includes(fileObject.type)
          ) {
            let allowedFileTypes = '';
            for (let fType of allowedMimeTypes) {
              const fileTypeSplitList = fType.split('/');
              const fileType = fileTypeSplitList[fileTypeSplitList.length - 1];
              allowedFileTypes = `${fileType}, `;
            }
            allowedFileTypes = allowedFileTypes.replace(/.{0,2}$/, '');
            setErrorMessage(`Only ${allowedFileTypes} files are allowed.`);
            setTimeout(() => {
              setErrorMessage('');
            }, 30000);
          } else {
            canUploadFiles.push(true);
          }
        }

        if (canUploadFiles?.length === target?.files?.length) {
          handleFileUpload(target.files);
          loader(0, uploadedFilesArray.length);
        }
      }
    } else {
      setErrorMessage('Maximum 12 files can be uploaded.');
      setFileUploadInprogress(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 30000);
    }
  };

  const getName = item => {
    if (item?.name) {
      return item.name;
    } else if (item?.files?.name) {
      return item.files.name;
    }
  };

  const inputRef = useRef(null);

  return (
    <>
      <form
        id="form-file-upload"
        onDragEnter={e => e.preventDefault()}
        onDragOver={e => e.preventDefault()}
        onDrop={handleUploadFile}
        onSubmit={e => e.preventDefault()}
      >
        <input
          type="file"
          multiple
          id="input-file-upload"
          onChange={handleUploadFile}
          ref={inputRef}
        />
        <label id="label-file-upload" htmlFor="input-file-upload">
          <div className="uploadContent">
            <Row>
              <Col sm={3}>
                <div className="uploadIconWrapper" title="Upload PDF">
                  <UploadPDFIcon />
                </div>
              </Col>
              <Col sm={9}>
                <div className="uploadHeadline">Select a file to upload</div>
                <div className="uploadSubheading">
                  {isMobile ? '' : 'or drag and drop your files here'}
                </div>
              </Col>
            </Row>
            <button className="upload-button"></button>
          </div>
        </label>
      </form>

      {errorMessage ? (
        <Alert
          className="mt-5"
          variant="danger"
          onClose={() => {
            setErrorMessage('');
          }}
          dismissible
        >
          <p>Error uploading file!</p>
          <p>{errorMessage}</p>
        </Alert>
      ) : (
        <></>
      )}

      <div className="uploadedFiles">
        {uploadedFilesArray.map((item, index) => (
          <div className="file">
            <Row>
              <Col sm={1}>
                <div className="pdfIcon">
                  <PDFIcon />
                </div>
              </Col>
              <Col sm={2}>
                <p>{getName(item)}</p>
              </Col>
              <Col sm={7}>
                <ProgressBar now={100} />
                <span>100% done</span>
              </Col>
              <Col sm={1}>
                <div className="completed">
                  <CheckIcon />
                </div>
              </Col>
              <Col sm={1}>
                <div
                  className="cancel"
                  onClick={() => handleDeleteFileFromArray(index)}
                >
                  x
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </>
  );
};

export default React.memo(DragAndDrop);
