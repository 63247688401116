import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { CloseIcon } from 'assets/images/CloseIcon/CloseIcon';
import { DownloadIcon } from 'assets/images/DownloadIcon/DownloadIcon';
import { ErrorIcon } from 'assets/images/ErrorIcon/ErrorIcon';
import {
  displayCompletionFormStatus,
  displayError,
} from 'components/common/Alert/ToastAlert';
import { Divider } from 'components/common/Divider/Divider';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { confirm } from 'components/Dialogs/Confirm';
import {
  getInvoiceStatusByKey,
  keyInvoiceTab,
  optionsInvoiceListTab,
} from 'constants/invoiceTabs.ts';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { ROUTES_STRING } from 'constants/routes';
import {
  InvoiceStatusDisplayValue,
  TimesheetStatusDisplayValue,
} from 'constants/statusValues';
import { ModuleACL, UserType } from 'enums/entitlements.ts';
import { InvoiceDetailList } from 'enums/fields.ts';
import { Page } from 'enums/page.ts';
import { InvoiceStatus, TimesheetStatus } from 'enums/statuses.ts';
import _ from 'lodash';
import { BuPath } from 'constants/buPath';
import { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  resetFilters,
  setInvoiceFilters,
  setTimesheetFilters,
} from 'store/slices/filterSlice';
import {
  fetchInvoices,
  exportInvoicesRequest,
  fetchSelectedInvoiceList,
  resetInvoices,
  setAnalyticsFilters,
  setInvoicePagination,
} from 'store/slices/Invoice/invoiceSlice';
import {
  fetchInvoiceApprovers,
  fetchInvoiceData,
  fetchUserEntitlements,
  fetchInvoiceUserEntitlements,
} from 'store/slices/lookupSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { fetchPurchaseOrderDetails } from 'store/slices/purchaseOrder/purchaseOrderDetailsSlice';
import {
  fetchPurchaseOrders,
  setPOPagination,
} from 'store/slices/purchaseOrder/purchaseOrderSlice';
import * as timsheetSlice from 'store/slices/timesheet/timesheetSlice';
import {
  fetchTimesheets,
  setTimesheetPagination,
} from 'store/slices/timesheet/timesheetSlice';
import { IsAllowedACL, filterAllowedOptionsACL } from 'utils/aclHelper';
import { isEmptyVal } from 'utils/utils';
import './InvoiceList.scss';

const InvoiceList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const type = 'Invoice';
  const user = useSelector(state => state.auth);
  const accessToken = useSelector(state => state.auth.accessToken);

  const invoiceList = useSelector(state => state.invoice.items);
  const invoiceListLoading = useSelector(state => state.invoice.isLoading);

  const inputListData = useSelector(state => state.filter.invoice);
  const selectedFilterRecordList = useSelector(
    state => state.invoice.recordItems,
  );

  const purchaseOrders = useSelector(state => state.purchaseOrder.items).filter(
    obj =>
      obj.status === 'P' ||
      obj.status === 'R' ||
      obj.status === 'V' ||
      obj.status === 'F' ||
      obj.status === 'C' ||
      obj.status === 'A',
  );
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);

  // Timesheet list variables
  const timesheets = useSelector(state => state.timesheet.items);
  const timesheetPagination = useSelector(state => state.timesheet.pagination);
  const timesheetCurrentPage = useSelector(
    state => state.timesheet.pagination.currentPage,
  );
  const timesheetSortBy = useSelector(
    state => state.timesheet.pagination.sortBy,
  );
  const timesheetSortOrder = useSelector(
    state => state.timesheet.pagination.sortOrder,
  );
  const selectedFilterRecordListTimesheet = useSelector(
    state => state.timesheet.recordItems,
  );
  const inputListDataTimesheet = useSelector(state => state.filter.timesheet);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState({
    label: 'Select',
    value: '',
    contactNumber: '',
  });
  const [pOOptionList, setPOOptionList] = useState([]);
  const [contractNumOptionList, setContractNumOptionList] = useState([]);
  // End Timesheet list variables

  const [key, setKey] = useState('all');
  const [modalShow, setModalShow] = useState(false);
  const [filterChip, setFilterchip] = useState(false);
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [chipFilterValuesT, setChipFilterValuesT] = useState({});
  const [filterChipT, setFilterchipT] = useState(false);
  const [createInvoiceError, setCreateInvoiceError] = useState(false);
  const [createInvoiceErrorMessage, setCreateInvoiceErrorMesage] = useState([]);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);
  const [submitPage, setSubmitPage] = useState(false);
  const [selectedPO, setSelectedPO] = useState('');
  const [selectedPONum, setSelectedPONum] = useState('');
  const [displayTable, setDisplayTable] = useState(false);

  const [selectedContractor, setSelectedContractor] = useState({
    label: 'Select',
    value: '',
  });
  const [poOrders, setPOOrders] = useState([]);
  const [timesheetIdList, setTimesheetIdList] = useState([]);
  const [includeComments, setIncludeComments] = useState(false);
  const pagination = useSelector(state => state.invoice.pagination);
  const [tabs, setTabs] = useState([]);

  const currentPage = useSelector(
    state => state.invoice.pagination.currentPage,
  );
  const sortBy = useSelector(state => state.invoice.pagination.sortBy);
  const sortOrder = useSelector(state => state.invoice.pagination.sortOrder);
  const selectedBupath = useSelector(
    state => state.purchaseOrderDetails?.buPath,
  );

  const TabKey = keyInvoiceTab;

  const optionsTab = optionsInvoiceListTab;

  const headers = [
    { key: InvoiceDetailList.vendorInvoiceNumber, value: 'Vendor Invoice No' },
    { key: InvoiceDetailList.invoiceNumber, value: 'Invoice No' },
    { key: InvoiceDetailList.contractNumber, value: 'Contract Number' },
    { key: InvoiceDetailList.poNumber, value: 'Purchase Order' },
    { key: InvoiceDetailList.vendor, value: 'Vendor Name' },
    { key: InvoiceDetailList.startDate, value: 'Start Date' },
    { key: InvoiceDetailList.endDate, value: 'End Date' },
    { key: InvoiceDetailList.status, value: 'Status' },
  ];

  const Invoicefilters = [
    { label: 'PurchaseOrder', value: 'ponum' },
    { label: 'Vendor Name', value: 'vendorName' },
    { label: 'Vendor Id', value: 'vendor' },
    { label: 'Site', value: 'siteid' },
    { label: 'Invoice Date', value: 'invoiceDate' },
    { label: 'Week End Date', value: 'weekEndDate' },
    { label: 'Work Area', value: 'workAreaShortDesc' },
    { label: 'Status', value: 'caastatus' },
    { label: 'BUPath', value: 'bupath' },
    { label: 'Week Start Date', value: 'weekStartDate' },
    { label: 'Maximo Invoice Number', value: 'maxInvNmbr' },
    { label: 'CAA Invoice Number', value: 'invoiceNmbr' },
    { label: 'Vendor Invoice Number', value: 'vendorInvNmbr' },
    { label: 'Contract', value: 'contractNmbr' },
    { label: 'Auth', value: 'authNmbr' },
  ];

  const timesheetHeaders = [
    { key: 'recordNmbr', value: 'Timesheet No.' },
    { key: 'ponum', value: 'Purchase Order' },
    { key: 'vendor', value: 'Vendor Name' },
    { key: 'workStartDate', value: 'Start Date' },
    { key: 'workEndDate', value: 'End Date' },
    { key: 'caastatus', value: 'Status' },
  ];

  const timesheetFiltersValues = [
    { label: 'Week end date', value: 'weekEndDate' },
    { label: 'Work Area', value: 'workAreaId' },
    { label: 'Work Order', value: 'transWorkorder' },
    { label: 'Crew Type', value: 'crewType' },
    { label: 'BU Path', value: 'bupath' },
    { label: 'Circuit No', value: 'circuitNmbr' },
    { label: 'Auth No', value: 'authNmbr' },
    { label: 'Contract ID', value: 'contractNmbr' },
    { label: 'Start Date', value: 'workStartDate' },
    { label: 'End Date', value: 'workEndDate' },
    // { label: 'Vendor', value: 'vendor' },
    { label: 'Work Type', value: 'worktype' },
    { label: 'UVL', value: 'estimate' },
  ];

  const { promiseInProgress: fetchInvoiceDataLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getInvoiceData,
    delay: 0,
  });

  const handleShowDetailsClick = (keyValue = 'all') => {
    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];
    let statusFilter = [];

    const keyOfstatus = getInvoiceStatusByKey(keyValue);
    if (!_.isEmpty(keyOfstatus)) {
      statusFilter.push(keyOfstatus);
    }

    inputListData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });
    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputListData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputListData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue && el.FilterLabel) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });
    if (statusFilter.length > 0) {
      selectedFilterData.caastatus = statusFilter.join(',');
    } else {
      selectedFilterData.caastatus = '';
    }
    selectedFilterData.currentPage = 1;
    dispatch(setAnalyticsFilters(selectedFilterData));
    navigate(`/${ROUTES_STRING.invoices}/analytics`);
  };

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setInvoicePagination({
        ...pagination,
        // currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
    handleContinueClick(key, true);
  };

  const handleSortingTimesheet = sortBy => {
    const sortOrder = timesheetSortOrder === 'desc' ? 'asc' : 'desc';
    dispatch(
      setTimesheetPagination({
        ...pagination,
        // currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
    handleContinueClickTimeSheet(key, true);
  };

  useEffect(() => {
    if (location?.state?.from !== 'invoiceDetail') {
      dispatch(resetFilters());
    }

    dispatch(resetInvoices());
    if (props.type === 'poinvoice') {
      dispatch(fetchInvoices({ ponum: purchaseOrder.ponum }));
    }

    dispatch(
      setActivePage(
        props.type !== 'poinvoice' ? Page.INVOICE : Page.PURCHASE_ORDER,
      ),
    );

    const optionsAllowed = filterAllowedOptionsACL(
      user,
      ModuleACL.INVOICE,
      optionsTab,
    );
    setTabs(optionsAllowed);

    if (location.state === 'fromHomePage') {
      handleCreateNewInvoice();
    }

    if (location?.state?.from === 'homePage') {
      handleContinueClick(location?.state?.status);
    }

    if (location?.state?.from === 'invoiceDetail') {
      handleContinueClick('all', false, inputListData);
    }

    if (props.type != 'poinvoice') {
      dispatch(fetchInvoices(inputListData));
    }
  }, [dispatch]);

  const handleInvoiceClick = row => {
    dispatch(
      // @ts-ignore
      fetchInvoiceUserEntitlements({
        entitlement: 'CAA-AEP-Invoice-Verifier',
        callback: () => {
          dispatch(
            fetchInvoiceApprovers({
              callback: () => {
                navigate(`/${ROUTES_STRING.invoices}/${row.invoiceNumber}`);
              },
            }),
          );
        },
      }),
    );
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleContinueClick = (
    keyValue = 'all',
    isFromSorting = false,
    data = null,
  ) => {
    setKey(keyValue);
    setModalShow(false);

    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }
    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];
    let statusFilter = [];

    const keyOfstatus = getInvoiceStatusByKey(keyValue);
    if (!_.isEmpty(keyOfstatus)) {
      statusFilter.push(keyOfstatus);
    }

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });
    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue && el.FilterLabel) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });

    let pageNumber = 1;
    if (!isEmptyVal(data) && inputListData?.length === data?.length) {
      pageNumber = pagination.currentPage;
    }
    if (!isFromSorting) {
      dispatch(
        setInvoicePagination({
          ...pagination,
          currentPage: pageNumber,
          caastatus: statusFilter.toString(),
        }),
      );
    }
    if (props.type === 'poinvoice') {
      dispatch(
        fetchInvoices({ ...selectedFilterData, ponum: purchaseOrder?.ponum }),
      );
    } else {
      let pageNumber = 1;
      if (!isEmptyVal(data) && inputListData?.length === data?.length) {
        pageNumber = pagination.currentPage;
      }
      dispatch(
        fetchInvoices({ ...selectedFilterData, currentPage: pageNumber }),
      );
    }
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue['Vendor Name'] != undefined) {
      selectedFilterValue['Vendor Name'].map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue['Vendor Name'] = vendorSelectFilterLabel;
        return selectedFilterValue['Vendor Name'];
      });
    }
    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleContinueClickTimeSheet = (
    keyValue = 'all',
    isFromSorting = false,
    data = null,
  ) => {
    let inputData = _.cloneDeep(inputListDataTimesheet);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];
    let statusFilter = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });
    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue && el.FilterLabel) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });
    if (statusFilter.length > 0) {
      selectedFilterData.caastatus = statusFilter.join(',');
    } else {
      selectedFilterData.caastatus = '';
    }
    let pageNumber = 1;
    if (!isEmptyVal(data) && inputListData?.length === data?.length) {
      pageNumber = pagination.currentPage;
    }
    if (!isFromSorting) {
      dispatch(
        setTimesheetPagination({
          ...pagination,
          currentPage: 1,
        }),
      );
    }
    selectedFilterData.caastatus = TimesheetStatus.TIMESHEET_APPROVED;

    selectedFilterData.ponum = selectedPONum;
    dispatch(
      fetchTimesheets({ ...selectedFilterData, currentPage: pageNumber }),
    );
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }
    setChipFilterValuesT(selectedFilterValue);
    setModalShow(false);
    setFilterchipT(true);
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendorName') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setInvoiceFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData['Vendor Name'] != undefined) {
      filterchipData['Vendor Name'].map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData['Vendor Name'] = vendorSelectFilterValue;
        return filterchipData['Vendor Name'];
      });
    }

    dispatch(fetchInvoices(filterchipData));
  };

  const handleFilterChipT = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValuesT).map(function (item, i) {
      let chipList = [...chipFilterValuesT[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValuesT[item] = chipList;
    });

    inputListDataTimesheet.map((el, id) => {
      Object.keys(chipFilterValuesT).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValuesT[item];
        }
      });
    });

    let newInputList1 = _.cloneDeep(inputListDataTimesheet);
    let indextodelete;
    newInputList1.map((val, ids) => {
      if (val.FilterValue == 'vendorName') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);
    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setTimesheetFilters(newInputList1));
    setChipFilterValuesT(chipFilterValuesT);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }
    filterchipData.caastatus = TimesheetStatus.TIMESHEET_APPROVED;
    filterchipData.poid = selectedPO;
    dispatch(fetchTimesheets(filterchipData));
  };

  const handleCreateNewInvoice = () => {
    // @ts-ignore
    dispatch(
      setPOPagination({
        itemsPerPage: 500,
        ponum: '',
        contractrefnum: '',
        caastatus: 'P,R,V,F,C,A,N',
        forInvoice: 'true',
      }),
    );
    dispatch(
      fetchPurchaseOrders({
        callback: data => {
          dispatch(setPOPagination({ forInvoice: '' }));
          if (!isEmptyVal(location?.state?.poId)) {
            setSelectedContractor({
              label: purchaseOrder?.contractRefnum,
              value: purchaseOrder?.poid,
            });
            setSelectedPurchaseOrder({
              label: purchaseOrder?.ponum,
              value: purchaseOrder?.poid,
            });
            handleSelectPOOption({
              contractNumber: purchaseOrder?.contractRefnum,
              label: purchaseOrder?.ponum,
              value: purchaseOrder?.poid,
            });
          }
          getPOOptions(data);
          getContractNumbers(data);
        },
      }),
    );
    setSelectedPO('');
    setSelectedPONum('');
    setSubmitPage(true);
    setChipFilterValuesT({});
    dispatch(timsheetSlice.resetTimesheets());
    dispatch(timsheetSlice.setInputFilterData([]));
  };

  const hideErrorModel = () => {
    setCreateInvoiceError(false);
  };

  const returnTimesheets = () => {
    let timesheetIds = [];
    timesheetIdList.forEach((id, index) => {
      timesheetIds.push(id);
    });

    dispatch(
      timsheetSlice.returnTimesheets({
        timesheetIds,
        callback: respData => {
          if (respData?.data?.error) {
            setCreateInvoiceError(true);
          } else {
            setCreateInvoiceError(false);
            displayCompletionFormStatus(
              'Timesheet associated with the Invoice has been returned',
            );
            dispatch(fetchPurchaseOrderDetails(selectedPO));
            dispatch(fetchTimesheets());
          }
        },
      }),
    );
  };

  const handleSubmit = async () => {
    const fiteredTimesheets = timesheets?.filter(timesheet =>
      timesheetIdList?.includes(timesheet?.recordNmbr),
    );

    const firstWeekEndDate =
      fiteredTimesheets?.length > 0 ? fiteredTimesheets[0]?.weekEndDate : null;
    const allSameWeekEndDate = fiteredTimesheets?.every(
      obj => obj?.weekEndDate === firstWeekEndDate,
    );

    if (!allSameWeekEndDate && BuPath.DIST.includes(selectedBupath)) {
      displayError(
        'Invoice cannot be created for Timesheets with different weekend dates',
      );
    } else {
      setCreateInvoiceError(false);
      if (
        !(await confirm({
          message: 'Do you want to generate invoice?',
          title: 'Generate Invoice',
        }))
      ) {
        return false;
      }
      let timesheetIds = [];
      timesheetIdList.forEach((id, index) => {
        if (index === timesheetIdList.length - 1) {
          timesheetIds += id;
        } else {
          timesheetIds += id + ',';
        }
      });
      if (!timesheetIds) {
        return;
      }
      const poId = selectedPO;
      dispatch(
        // @ts-ignore
        fetchInvoiceUserEntitlements({
          entitlement: 'CAA-AEP-Invoice-Verifier',
          callback: () => {
            dispatch(fetchInvoiceApprovers());
            dispatch(
              // @ts-ignore
              fetchInvoiceData({
                poId,
                timesheetIds,
                validateAccounts: true,
                callback: respData => {
                  if (respData?.status !== 200) {
                    if (respData?.data?.details) {
                      setCreateInvoiceErrorMesage(respData?.data?.details);
                      setCreateInvoiceError(true);
                    }
                  } else {
                    navigate(
                      {
                        pathname: `/${ROUTES_STRING.invoices}/new`,
                        search: `${createSearchParams({
                          poId,
                          timesheetIds,
                          includeComments: String(includeComments),
                        })}`,
                      },
                      {
                        // search:`${createSearchParams({
                        //   poId: selectedPO,
                        //   timesheetIds,
                        // })}`,
                        state: {
                          status: InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED,
                        },
                      },
                    );
                  }
                },
              }),
            );
          },
        }),
      );
    }
  };
  const handleIncludeComment = e => {
    setIncludeComments(e.target.checked);
  };
  const handleTimesheetCheck = (e, index, allSelected = false) => {
    if (allSelected) {
      if (e.target.checked) {
        const tIdList = [];
        timesheets.forEach(obj => {
          tIdList.push(obj.recordNmbr);
        });

        setTimesheetIdList([...tIdList]);
      } else {
        setTimesheetIdList([]);
      }
    } else {
      const timesheetId = timesheets[index].recordNmbr;
      if (e.target.checked) {
        timesheetIdList.push(timesheetId);
      } else {
        const indx = timesheetIdList.indexOf(timesheetId);
        if (indx >= 0) {
          timesheetIdList.splice(indx, 1);
        }
      }
      setTimesheetIdList([...timesheetIdList]);
    }
  };

  const handleSelectPOOption = e => {
    const poIdSelected = e !== undefined ? e.value : e;
    const poNumSelected = e !== undefined ? e.label : e;
    if (poIdSelected && !isNaN(poIdSelected)) {
      if (pOOptionList.length) {
        setSelectedPurchaseOrder(
          pOOptionList.find(obj => obj.value === e.value),
        );
      } else {
        setSelectedPurchaseOrder({
          label: purchaseOrder?.ponum,
          value: purchaseOrder?.poid,
        });
      }
      setSelectedContractor({ label: e.contractNumber, value: e.value });
      dispatch(
        setTimesheetPagination({
          itemsPerPage: 10,
          caastatus: TimesheetStatus.TIMESHEET_APPROVED,
          ponum: poNumSelected,
          noInvoice: true,
          currentPage: 1,
        }),
      );
      dispatch(
        fetchPurchaseOrderDetails({
          poId: poIdSelected,
          callback: data => {
            let ewopt = 0;
            if (data.pocostactivities && data.pocostactivities.length > 0) {
              for (let i = 0; i < data.pocostactivities.length; i++) {
                if (data.pocostactivities[i].ewopt) {
                  ewopt = 1;
                }
              }
            }
            if (!ewopt) {
              setDisplayTable(true);
            } else {
              setDisplayTable(false);
            }
            setSelectedPO(poIdSelected);
            setSelectedPONum(poNumSelected);
          },
        }),
      );
      dispatch(fetchTimesheets());
    }
  };

  const getContractNumbers = purchaseOrderList => {
    const options = [];
    purchaseOrderList.forEach((po, index) => {
      options.push({ label: po.contractNumber, value: po.poId });
    });
    const contractOptions = _.uniqBy(options, 'label');
    setContractNumOptionList(contractOptions);
    return contractOptions;
  };

  const getPOOptions = purchaseOrderList => {
    const options = [];
    purchaseOrderList.forEach(po => {
      options.push({
        label: po.poNumber,
        value: po.poId,
        contractNumber: po.contractNumber,
      });
    });
    setPOOptionList(options);
  };

  const handleDownloadInvoice = (keyValue = 'all', isFromSorting = false) => {
    setKey('all');
    setModalShow(false);
    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];
    let statusFilter = [];

    const keyOfstatus = getInvoiceStatusByKey('all');
    if (!_.isEmpty(keyOfstatus)) {
      statusFilter.push(keyOfstatus);
    }

    inputListData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });
    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputListData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    // inputListData.map((el, id) => {
    //   Object.keys(selectedFilterData).map(function (item) {
    //     if (item == el.FilterValue && el.FilterLabel) {
    //       selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
    //     }
    //   });
    // });

    // if (!isFromSorting) {
    //   dispatch(
    //     setInvoicePagination({
    //       ...pagination,
    //       currentPage: 1,
    //       caastatus: statusFilter.toString(),
    //     }),
    //   );
    // }
    dispatch(exportInvoicesRequest(selectedFilterData));
  };

  const handleInputChange = value => {
    if (!isEmptyVal(value)) {
      dispatch(
        setPOPagination({ itemsPerPage: 500, ponum: '', contractrefnum: '' }),
      );
      dispatch(
        fetchPurchaseOrders({
          ponum: value,
          callback: data => {
            getPOOptions(data);
            getContractNumbers(data);
            setSelectedContractor({ label: 'Select', value: '' });
          },
        }),
      );
    }
  };

  const handleContractInputChange = value => {
    if (!isEmptyVal(value)) {
      dispatch(
        setPOPagination({ itemsPerPage: 500, ponum: '', contractrefnum: '' }),
      );
      dispatch(
        fetchPurchaseOrders({
          contractrefnum: value,
          callback: data => {
            getPOOptions(data);
            getContractNumbers(data);
            setSelectedContractor({ label: 'Select', value: '' });
            setDisplayTable(false);
          },
        }),
      );
    }
  };

  const debouncedContractInputChange = _.debounce(
    handleContractInputChange,
    500,
  );

  const debouncedInputChange = _.debounce(handleInputChange, 500);

  const handleSelectContactor = e => {
    setDisplayTable(false);
    setSelectedPurchaseOrder({
      label: 'Select',
      value: '',
      contactNumber: '',
    });
    let contractNum = '';
    if (isEmptyVal(e)) {
      setSelectedContractor({ label: 'Select', value: '' });
    } else {
      contractNum = e.value;
      setSelectedContractor({ label: e.label, value: e.value });
    }

    dispatch(
      setPOPagination({
        itemsPerPage: 500,
        ponum: '',
        contractrefnum: contractNum,
      }),
    );
    dispatch(
      fetchPurchaseOrders({
        callback: data => {
          getPOOptions(data);
          getContractNumbers(data);
        },
      }),
    );
  };

  return (
    <>
      {props.type !== 'poinvoice' && (
        <>
          <div className="aepContainer aepHeader">Invoices</div>
          <div
            className="aepContainer aepBreadcrumb"
            style={{ display: 'flex' }}
          >
            <div
              className="backArrow"
              onClick={() => {
                setSubmitPage(false);
              }}
              title="Back"
            >
              <BackArrowIcon />
            </div>
            Create New Invoice
          </div>
        </>
      )}

      {submitPage ? (
        <>
          <div
            className="aepContainer"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Row style={{ width: '100%' }}>
              <Col sm={3}>
                <Form>
                  <Form.Group controlId="form.poOptions">
                    <Form.Label>Contract Number: </Form.Label>
                    <ReactSelect
                      id="poReactSelect"
                      classNamePrefix="poSelect"
                      value={selectedContractor}
                      isClearable={false}
                      isSearchable={true}
                      name="contact"
                      placeholder="Select"
                      options={contractNumOptionList}
                      menuPosition="absolute"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: base => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: '16px',
                        }),
                      }}
                      maxMenuHeight={600}
                      onChange={e => handleSelectContactor(e)}
                      onInputChange={e => debouncedContractInputChange(e)}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col sm={3}>
                <Form>
                  <Form.Group controlId="form.poOptions">
                    <Form.Label>Purchase Order Number:</Form.Label>
                    <ReactSelect
                      id="poReactSelect"
                      classNamePrefix="poSelect"
                      value={selectedPurchaseOrder}
                      isClearable={false}
                      isSearchable={true}
                      name="po"
                      placeholder="Select"
                      options={pOOptionList}
                      menuPosition="absolute"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: base => ({
                          ...base,
                          zIndex: 9999,
                          fontSize: '16px',
                        }),
                      }}
                      maxMenuHeight={600}
                      onChange={e => handleSelectPOOption(e)}
                      onInputChange={e => debouncedInputChange(e)}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>

          {selectedPO !== '' && (
            <>
              <div className="aepContainer">
                <Tabs
                  id="timesheetPage"
                  activeKey={'timesheetApproved'}
                  onSelect={k => setKey(k)}
                >
                  <Tab
                    eventKey="timesheetApproved"
                    title="Approved"
                    tabClassName="tab-item"
                  >
                    <div className="tableContainer">
                      <Row>
                        <Col sm={11}>
                          <>
                            <div className="filterComponent">
                              <AEPFilterComponent
                                inputListData={inputListDataTimesheet}
                                filterValues={timesheetFiltersValues}
                                setInputFilterData={setTimesheetFilters}
                                selectedFilterRecordList={
                                  selectedFilterRecordListTimesheet
                                }
                                fetchSelectedList={
                                  timsheetSlice.fetchSelectedTimesheetList
                                }
                                handleContinueClick={
                                  handleContinueClickTimeSheet
                                }
                                continueBtnDisable={continueBtnDisable}
                                handleFilterclose={handleFilterclose}
                                handleFilterChip={handleFilterChipT}
                                filterChip={filterChipT}
                                chipFilterValues={chipFilterValuesT}
                              />
                            </div>
                          </>
                        </Col>
                      </Row>

                      <Table hover responsive striped bordered={false}>
                        <thead>
                          <tr>
                            {timesheetHeaders.map((header, idx) => (
                              <th key={idx}>
                                {idx === 0 ? (
                                  <div
                                    className="invTimesheetCheckDiv"
                                    style={{ display: idx === 0 ? 'flex' : '' }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        timesheetIdList.length ===
                                        timesheets.length
                                      }
                                      onChange={e =>
                                        handleTimesheetCheck(e, idx, true)
                                      }
                                    />
                                    <div
                                      id={header.key}
                                      onClick={e =>
                                        handleSortingTimesheet(header.key)
                                      }
                                    >
                                      {header.value}
                                      {timesheetSortOrder === 'desc' ? (
                                        <CaretDown
                                          style={{ marginLeft: '12px' }}
                                        />
                                      ) : (
                                        <CaretUp
                                          style={{ marginLeft: '12px' }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    onClick={e =>
                                      handleSortingTimesheet(header.key)
                                    }
                                  >
                                    {header.value}
                                    {timesheetSortOrder === 'desc' ? (
                                      <CaretDown
                                        style={{ marginLeft: '12px' }}
                                      />
                                    ) : (
                                      <CaretUp style={{ marginLeft: '12px' }} />
                                    )}
                                  </div>
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {timesheets.map((row, indx) => (
                            <tr
                              key={indx}
                              className="tRow table-hover-row"
                              // onClick={e => handleTimesheetClick(row)}
                            >
                              <td>
                                <div className="invTimesheetCheckDiv">
                                  <input
                                    type="checkbox"
                                    checked={
                                      timesheetIdList.indexOf(row.recordNmbr) >=
                                      0
                                    }
                                    onChange={e =>
                                      handleTimesheetCheck(e, indx)
                                    }
                                  />
                                  {row.recordNmbr}
                                </div>
                              </td>
                              <td>{row.ponum || row?.po?.ponum}</td>
                              <td>
                                {row.vendorTimesheet ||
                                  row?.po?.vendorVendor?.vendor_name}
                              </td>
                              <td>{row.workStartDate}</td>
                              <td>{row.workEndDate}</td>
                              <td>
                                {TimesheetStatusDisplayValue[row.caastatus]}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {displayTable && (
                        <AEPPagination
                          fetchItems={fetchTimesheets}
                          setPagination={setTimesheetPagination}
                          pagination={timesheetPagination}
                          currentPage={timesheetCurrentPage}
                          sortBy={timesheetSortBy}
                          sortOrder={timesheetSortOrder}
                        />
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
              <div className="container">
                <div>
                  <div className="invTimesheetCheckDiv">
                    <input
                      id="includeComments"
                      type="checkbox"
                      checked={includeComments}
                      onChange={handleIncludeComment}
                    />
                    <label htmlFor="includeComments">
                      Include comments from Timesheets into Invoice
                    </label>
                  </div>
                </div>
                <div className="btnArea" style={{ marginRight: '12px' }}>
                  {((user.userType === UserType.VENDOR &&
                    !BuPath.DIST.includes(purchaseOrder?.buPath) &&
                    !purchaseOrder.ewotk) ||
                    user.userType === UserType.AEP ||
                    user?.configObject?.userrole === 'admin') && (
                    <Button
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={timesheetIdList.length === 0}
                    >
                      Create Invoice
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className={props.type !== 'poinvoice' ? 'aepContainer' : ''}>
            <Tabs
              id="invoiceListTab"
              activeKey={key}
              onSelect={k => {
                handleContinueClick(k);
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  eventKey={tab.key}
                  title={tab.value}
                  key={index}
                  tabClassName="tab-item"
                >
                  <div className="tableContainer">
                    <Row>
                      <Col sm={11}>
                        <div className="filterComponent">
                          {props?.type !== 'poinvoice' && (
                            <AEPFilterComponent
                              inputListData={inputListData}
                              filterValues={Invoicefilters}
                              setInputFilterData={setInvoiceFilters}
                              selectedFilterRecordList={
                                selectedFilterRecordList
                              }
                              fetchSelectedList={fetchSelectedInvoiceList}
                              handleContinueClick={handleContinueClick}
                              continueBtnDisable={continueBtnDisable}
                              handleFilterclose={handleFilterclose}
                              handleFilterChip={handleFilterChip}
                              filterChip={filterChip}
                              chipFilterValues={chipFilterValues}
                            />
                          )}
                        </div>
                      </Col>
                      <Col sm={1}>
                        {props?.type !== 'poinvoice' && (
                          <div className="feature-btn filterIcons">
                            <div
                              style={{ margin: '5px' }}
                              onClick={() =>
                                !continueBtnDisable && handleShowDetailsClick()
                              }
                              className={
                                continueBtnDisable ? 'disable-icon' : ''
                              }
                              title="Analytics Report"
                            >
                              {/* <AnalyticsReportsIcon /> */}
                            </div>
                            <div
                              style={{ margin: '5px' }}
                              onClick={handleDownloadInvoice}
                              title="Download Invoice"
                            >
                              <DownloadIcon />
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Table hover responsive striped bordered={false}>
                      <thead>
                        <tr>
                          {headers.map((header, idx) => (
                            <th key={idx}>
                              {sortOrder === 'asc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(header.key, 'desc')
                                  }
                                >
                                  {header.value}
                                  <CaretUp style={{ marginLeft: '12px' }} />
                                </div>
                              )}
                              {sortOrder === 'desc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(header.key, 'asc')
                                  }
                                >
                                  {header.value}
                                  <CaretDown style={{ marginLeft: '12px' }} />
                                </div>
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceList.map((row, indx) => (
                          <tr
                            key={indx}
                            className="tRow table-hover-row"
                            onClick={e => handleInvoiceClick(row)}
                          >
                            <td>{row.vendorInvoiceNumber}</td>
                            <td>{row.invoiceNumber}</td>
                            <td>{row.contractNumber}</td>
                            <td>{row.poNum}</td>
                            <td>{row.vendor}</td>
                            <td>{row.startDate}</td>
                            <td>{row.endDate}</td>
                            <td>{InvoiceStatusDisplayValue[row.status]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {tab.key === key && (
                      <AEPPagination
                        fetchItems={fetchInvoices}
                        setPagination={setInvoicePagination}
                        pagination={pagination}
                        currentPage={currentPage}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                      />
                    )}
                  </div>
                </Tab>
              ))}
            </Tabs>
            {IsAllowedACL(
              user,
              ModuleACL.INVOICE,
              'can_submit_new_invoice',
            ) && (
              <div className="submitButtonDiv">
                <Button variant="primary" onClick={handleCreateNewInvoice}>
                  Create New Invoice
                </Button>
              </div>
            )}
          </div>
        </>
      )}

      <Modal
        show={createInvoiceError}
        onHide={hideErrorModel}
        size="lg"
        dialogClassName="invoicePreviewDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={false}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              height: '26px',
            }}
          >
            <div className="errorIcon">
              <ErrorIcon styles={{ marginTop: '-4px' }} />
            </div>
            Invoice accounting validation failed!
            <div
              style={{ marginLeft: 'auto', cursor: 'pointer' }}
              onClick={hideErrorModel}
            >
              <CloseIcon />
            </div>
          </div>
        </Modal.Header>
        <Divider />
        <Modal.Body className="psValidationModalBody">
          <div style={{ fontSize: '20px', marginBottom: '24px' }}>
            {createInvoiceErrorMessage['fint:errorDetail'] ? (
              createInvoiceErrorMessage['fint:errorDetail'].map(
                (error, index) => [
                  <div style={{ padding: '5px' }}>
                    <Row>
                      <Col sm={3}>Work Order No:</Col>
                      <Col sm={9}>{createInvoiceErrorMessage.workorder}</Col>
                    </Row>
                    <Row>
                      <Col sm={3}>PS Message Set number:</Col>
                      <Col sm={9}>{error['val:peopleSoftMessageSet']}</Col>
                    </Row>
                    <Row>
                      <Col sm={3}>Message Number:</Col>
                      <Col sm={9}>{error['val:peopleSoftMessageNumber']}</Col>
                    </Row>
                    <Row>
                      <Col sm={3}>Error Message Description:</Col>
                      <Col sm={9}>{error['val:errorDescription']}</Col>
                    </Row>
                  </div>,
                  <Divider />,
                ],
              )
            ) : (
              <ul>
                <li>Please select how you would like to proceed.</li>
              </ul>
            )}
          </div>
          <div className="btnArea">
            <Fragment>
              <Button variant="primary" onClick={() => returnTimesheets()}>
                Return Timesheets
              </Button>
              <Button variant="primary" onClick={() => handleSubmit()}>
                Try Again
              </Button>
              <Button variant="secondary" onClick={hideErrorModel}>
                Cancel
              </Button>
            </Fragment>
          </div>
        </Modal.Body>
      </Modal>

      <ClipLoader
        color="#009cde"
        loading={fetchInvoiceDataLoading || invoiceListLoading}
        cssOverride={{
          top: '50vh',
          left: '50vw',
          position: 'absolute',
          zIndex: 1,
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export { InvoiceList };
