import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import {
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { ConfirmationModal } from 'components/common/modals/ConfirmatinModal';
import { BuPath } from 'constants/buPath';
import { InvoiceStatus } from 'enums/statuses.ts';
import { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRdaData,
  updateInvoice,
} from 'store/slices/Invoice/invoiceDetailSlice';
import { IsAllowedACL } from 'utils/aclHelper';
import AccountingHeader from './AccountingHeader';
import DistributionHeader from './DistributionHeader';

import { ModuleACL } from 'enums/entitlements.ts';
import './Accounting.scss';
import { isEmptyVal } from 'utils/utils';
import _ from 'lodash';

const Accounting = props => {
  const dispatch = useDispatch();
  const { handleInvoiceSave, saveInProgress } = props;
  const invoice = useSelector(state => state.invoiceDetails);
  const costData = invoice.allCostLine;
  const [showLoadTSAModal, setShowLoadTSAModal] = useState(false);
  const [expand, setExpand] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  const [validateEnabled, setValidateEnabled] = useState(false);

  const activityamount = invoice.invoiceAmount;
  const user = useSelector(state => state.auth);

  // added fix for ECA-12957
  const canEditActivity =
    IsAllowedACL(user, ModuleACL.INVOICE, 'can_edit_invoice') &&
    (isEmptyVal(invoice.invoiceNmbr) ||
      invoice?.caastatus === InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED ||
      invoice?.caastatus ===
        InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED_RETURNED ||
      invoice?.caastatus ===
        InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED ||
      invoice?.caastatus === InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED);

  const handleLoadTSA = () => {
    setShowLoadTSAModal(false);
    if (invoice?.timesheetAccounts?.length) {
      const tsadata = invoice.timesheetAccounts.map(item => {
        let eachPercent = (item.lineAmount / activityamount) * 100;
        let eachLineAMount = item.lineAmount;
        if (!activityamount) {
          eachPercent = Number(100 / invoice.timesheetAccounts.length);
          eachLineAMount = 0;
        }
        return {
          activityNum: item.activityNum,
          workorder: item.workorder,
          abmsProject: item.abmsProject,
          accountNmbr: item.accountNmbr,
          projectBusinessUnit: item.projectBusinessUnit,
          glbu: item.glbu,
          costComponent: item.costComponent,
          abmsActivity: item.abmsActivity,
          dept: item.dept,
          linecost: eachLineAMount,
          percentage: Number(eachPercent.toFixed(2)),
        };
      });
      let totalAccountingPercent = _.sumBy(tsadata, 'percentage');
      let totalLineAmount = _.sumBy(tsadata, 'linecost');
      if (totalAccountingPercent != 100) {
        let lastIndex = tsadata.length - 1;
        let differenceinPercentage =
          Math.round((100 - totalAccountingPercent) * 100) / 100;
        tsadata[lastIndex].percentage =
          tsadata[lastIndex].percentage + differenceinPercentage;
        tsadata[lastIndex].percentage = Number(
          tsadata[lastIndex].percentage.toFixed(2),
        );
      }
      if (activityamount && totalLineAmount != activityamount) {
        let lastIndex = tsadata.length - 1;
        let differenceinAmount =
          Math.round((activityamount - totalLineAmount) * 100) / 100;
        tsadata[lastIndex].linecost =
          tsadata[lastIndex].linecost + differenceinAmount;
        tsadata[lastIndex].linecost = Number(
          tsadata[lastIndex].linecost.toFixed(2),
        );
      }
      const data = {
        allCostLine: [
          {
            activity: invoice.purchaseOrder.ponum,
            costline: tsadata,
          },
        ],
      };
      setExpand(false);
      dispatch(setRdaData(data));
    } else {
      setExpand(false);
      displayError('No TSA Data Avaialble');
    }
  };
  const validateCostLines = data => {
    for (const item of data) {
      for (const costlineItem of item.costline) {
        // Check if any required property is missing or empty
        if (
          !costlineItem.workorder ||
          !costlineItem.accountNmbr ||
          !costlineItem.glbu ||
          !costlineItem.dept
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const checkEquality = () => {
    let finalTotalCompare = [];

    // Iterate through each activity in the invoice
    for (let z = 0; z < invoice?.invoiceLines?.length; z++) {
      let totalAmount = 0; // Reset totalAmount for each activity

      // Iterate through each costline for the current activity
      for (let i = 0; i < costData?.length; i++) {
        for (let j = 0; j < costData[i]?.costline?.length; j++) {
          if (costData[i]?.costline[j]?.activityNum) {
            if (
              costData[i].costline[j].activityNum ===
              invoice.invoiceLines[z].activitynum
            ) {
              totalAmount += Number(costData[i]?.costline[j]?.linecost);
            }
          } else {
            totalAmount += Number(costData[i]?.costline[j]?.linecost);
          }
        }
      }

      // Push an object containing activity, totalAmount, and quantity to finalTotalCompare
      finalTotalCompare.push({
        activity: invoice?.invoiceLines[z]?.activitynum,
        totalAmount: Number(totalAmount.toFixed(2)),
        quantity: BuPath.DIST.includes(
          invoice?.bupath ? invoice?.bupath : invoice?.purchaseOrder?.bupath,
        )
          ? invoice.invoiceAmount
          : invoice?.invoiceLines[z]?.quantity,
      });
    }

    // Iterate through the array
    for (let i = 0; i < finalTotalCompare?.length; i++) {
      // If totalAmount is not equal to quantity, return false
      if (finalTotalCompare[i].totalAmount !== finalTotalCompare[i].quantity) {
        return false;
      }
    }
    // If all objects have totalAmount equal to quantity, return true
    return true;
  };

  const handleValidateSave = () => {
    setValidateEnabled(true);
    const isValid = validateCostLines(costData);
    const result = checkEquality();
    if (!isValid) {
      displayError('Accounting Details can not be blank.');
      setValidateEnabled(false);
    } else if (props.invoiceVerifier === props.invoiceApprover) {
      displayError(
        'Invoice verifier and invoice approver cannot be the same user.',
      );
      setValidateEnabled(false);
    } else if (!result) {
      displayError(
        'The total amount distributed for invoice line does not = original invoice line amount.',
      );
      setValidateEnabled(false);
    } else {
      let finalInvoiceCosts = [];
      let finalInvoiceAccounts = [];
      for (let i = 0; i < costData.length; i++) {
        for (let j = 0; j < costData[i].costline.length; j++) {
          let costdata = costData[i].costline[j];
          finalInvoiceCosts.push({
            costlinenum: j + 1,
            gldebitacct: `${costdata.glbu}-${costdata.dept}-${costdata.accountNmbr}-${costdata.workorder}-${costdata.projectBusinessUnit}-${costdata?.abmsProject}-${costdata.abmsActivity}-${costdata.costComponent}`,
            percentage: costdata?.percentage,
            orgid: invoice.purchaseOrder.orgid,
            siteid: invoice.purchaseOrder.siteid,
            invoicelinenum: BuPath.DIST.includes(invoice.bupath)
              ? invoice.invoiceLines[0].invoiceLineNmbr
              : invoice.invoiceLines[0].invoiceLineNmbr, // need to add logic for trans/generation
            activitynum: costData[i].activity,
            linecost: costdata?.linecost,
            unitcost: costdata?.linecost < 0 ? -1 : 1,
            quantity:
              costdata?.linecost < 0
                ? costdata?.linecost * -1
                : costdata?.linecost,
          });

          finalInvoiceAccounts.push({
            glbu: costdata.glbu ? costdata.glbu : '',
            dept: costdata.dept ? costdata.dept : '',
            accountNmbr: costdata.accountNmbr ? costdata.accountNmbr : '',
            workorder: costdata.workorder ? costdata.workorder : '',
            projectBusinessUnit: costdata.projectBusinessUnit
              ? costdata.projectBusinessUnit
              : '',
            abmsProject: costdata.abmsProject ? costdata.abmsProject : '',
            abmsActivity: costdata.abmsActivity ? costdata.abmsActivity : '',
            costComponent: costdata.costComponent ? costdata.costComponent : '',
            statejuris: '',
            productcode: '',
            resourcesubcategory: '',
          });
        }
      }
      let patchData = {};
      if (finalInvoiceCosts.length) {
        patchData = {
          invoiceNumber: invoice?.invoiceNmbr,
          invoiceCosts: finalInvoiceCosts,
          invoiceAccounts: finalInvoiceAccounts,
        };
      } else {
        patchData = {
          invoiceNumber: invoice?.invoiceNmbr,
        };
      }

      dispatch(
        // @ts-ignore
        updateInvoice({
          invoiceNumber: invoice?.invoiceNmbr,
          data: patchData,
          callback: async data => {
            if (data) {
              displaySuccess('Successfully Added Accounting to  the invoice');
            } else {
              displayError('Invoice Addition failed');
            }
            setValidateEnabled(false);
          },
        }),
      );
    }
  };

  return (
    <>
      <div className="accountingSection">
        {BuPath.DIST.includes(
          invoice?.bupath ? invoice?.bupath : invoice?.purchaseOrder?.bupath,
        ) ? (
          <DistributionHeader
            invoiceRow={invoice}
            setSelectedData={setSelectedData}
            setExpand={setExpand}
            expand={expand}
            canEditActivity={canEditActivity}
          />
        ) : (
          <AccountingHeader
            invoiceRow={invoice}
            setSelectedData={setSelectedData}
            canEditActivity={canEditActivity}
          />
        )}
      </div>

      {IsAllowedACL(user, ModuleACL.INVOICE, 'can_edit_invoice') &&
        (invoice?.caastatus === InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED ||
          invoice?.caastatus ===
            InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED_RETURNED ||
          invoice?.caastatus === InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED) &&
        (invoice.activities.length > 0 ||
          BuPath.DIST.includes(invoice.bupath)) && (
          <Fragment>
            <Row>
              <Col sm={6} />
              <Col sm={6}>
                <ButtonArea
                  primaryLabel="Validate & Save"
                  secondaryLabel="Load TSA"
                  primaryOnClick={
                    invoice?.invoiceNmbr
                      ? handleValidateSave
                      : handleInvoiceSave
                  }
                  secondaryOnClick={() => {
                    setShowLoadTSAModal(true);
                    setExpand(true);
                  }}
                  primaryDisabled={validateEnabled || saveInProgress}
                  secondaryDisabled={
                    BuPath.DIST.includes(
                      invoice?.bupath
                        ? invoice?.bupath
                        : invoice?.purchaseOrder?.bupath,
                    )
                      ? false
                      : true
                  }
                />
              </Col>
            </Row>
          </Fragment>
        )}

      <ConfirmationModal
        showModal={showLoadTSAModal}
        setShowModal={setShowLoadTSAModal}
        handleContinueClick={() => handleLoadTSA()}
        title="Load TSA Form"
        body="Are you sure you want to replace all data ?"
      />
    </>
  );
};

export default Accounting;
