import { createSlice } from '@reduxjs/toolkit';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import _ from 'lodash';

const initialState = {
  isLoading: false,
  error: false,
  maximoInvoiceError: false,
  maxErrorTitle: '',
  maximoErrors: [],
  errorResponse: {},
  vendorInvNmbr: '',
  invoiceNmbr: '',
  ponum: '',
  contractNmbr: '',
  weekStartDate: '',
  weekEndDate: '',
  bupath: '',
  caastatus: '',
  fuelAdjCost: null,
  exceedsPOCommitted: false,
  exceedsPOLineThreshold: false,
  carryCommentsTimesheet: false,
  timesheets: [],
  activities: [],
  invoiceAttachments: [],
  invoiceStatushistories: [],
  [SERVICE_TYPES.LABOR]: [],
  [SERVICE_TYPES.EQUIPMENT]: [],
  [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [],
  [SERVICE_TYPES.UNIT_PRICE_TASK]: [],
  totalActivity: 0,
  totalLaborAmount: 0,
  totalEquipmentAmount: 0,
  totalReimbursable: 0,
  totalUpTask: 0,
  totalLumpsum: 0,
  allCostLine: [],
  retentionWithheld: false,
  travelAmount: 0,
  smallJobAmount: 0,
  deEnergizedAmount: 0,
  invoiceApprover: '',
  invoiceVerifier: '',
};

const invoiceDetailSlice = createSlice({
  name: 'invoiceDetailSlice',
  initialState,

  reducers: {
    fetchInvoiceDetails(state) {
      state.isLoading = false;
    },
    fetchRDADetails(state) {
      state.isLoading = false;
    },
    updateInvoice(state) {},
    submitToMaximoPSValidation(state, action) {},
    sendInvoiceToMaximo(state, action) {},
    submitToMaximoAttachDocument(state, action) {},

    setCreateInvoiceError(state, action) {
      const stateValues = { ...state };
      let hasError =
        _.isArray(action.payload) &&
        action.payload?.some(
          item => item?.ValidationResponse['fint:numberOfErrors'] != 0,
        );
      stateValues.maximoInvoiceError = action.payload.showError;
      if (hasError) {
        stateValues.maximoErrors = action.payload?.reduce((errors, item) => {
          if (item?.ValidationResponse['fint:numberOfErrors'] != 0) {
            let errorDetails =
              item.ValidationResponse['fint:errorDetail'] || [];
            errorDetails.forEach(detail => {
              if (detail['val:errorMessageText']) {
                errors.push(detail['val:errorMessageText']);
              }
            });
          }
          return errors;
        }, []);
      } else if (action.payload?.returnTimesheetError) {
        action.payload.errorTitle = 'Unable to return the timesheet';
        stateValues.maximoErrors = action.payload?.returnTimesheetError?.map(
          item => {
            return item;
          },
        );
      } else if (action.payload?.details?.error) {
        stateValues.maximoErrors = action.payload?.details?.error
          ?.Longdescription && [
          action.payload?.details?.error?.Longdescription,
        ];
      } else if (action.payload?.details) {
        stateValues.maximoErrors = action.payload?.details[
          'fint:errorDetail'
        ].map(item => {
          return item['val:errorMessageText'];
        });
      } else if (action.payload[0]?.ValidationResponse['fint:errorDetail']) {
        stateValues.maximoErrors = action.payload[0]?.ValidationResponse[
          'fint:errorDetail'
        ].map(item => {
          return item['val:errorMessageText'];
        });
      } else if (action.payload?.errorDetails?.details?.Longdescription) {
        if (_.isArray(action.payload?.errorDetails?.details?.Longdescription)) {
          stateValues.maximoErrors =
            action.payload?.errorDetails?.details?.Longdescription?.map(
              item => {
                return item;
              },
            );
        } else {
          stateValues.maximoErrors =
            action.payload?.errorDetails?.details?.Longdescription;
        }
      } else {
        if (action.payload?.error?.message) {
          stateValues.maximoErrors = action.payload?.error?.message;
        } else if (action.payload?.error?.error?.ERRORMESSAGE) {
          stateValues.maximoErrors = action.payload?.error?.error?.ERRORMESSAGE;
        }
      }
      stateValues.maxErrorTitle = action.payload.errorTitle
        ? action.payload.errorTitle
        : 'Failed to create invoice in Maximo!';
      return { ...stateValues };
    },

    setInvoiceDetails(state, action) {
      if (action.payload?.invoiceCosts) {
        let invoiceCostsData = {};
        let finalObj = [];
        if (
          ['FORESTRY', 'DISTRIBUTION'].includes(
            action.payload.bupath
              ? action.payload.bupath
              : action.payload.purchaseOrder.bupath,
          )
        ) {
          invoiceCostsData = action.payload?.invoiceCosts?.length
            ? action.payload.invoiceCosts.map(item => {
                const parts = item.gldebitacct.split('-');
                return {
                  activityNum: action.payload.invoiceCosts.activityNum,
                  workorder: parts[3],
                  abmsProject: parts[5],
                  accountNmbr: parts[2],
                  projectBusinessUnit: parts[4],
                  glbu: parts[0],
                  costComponent: parts[7],
                  abmsActivity: parts[6],
                  dept: parts[1],
                  linecost: Number(item.linecost).toFixed(2),
                  percentage: Number(item.percentage).toFixed(2),
                  invoicecostlinenum: item.invoicecostlinenum,
                };
              })
            : [
                {
                  activityNum: '',
                  workorder: '',
                  abmsProject: '',
                  accountNmbr: '',
                  projectBusinessUnit: '',
                  glbu: '',
                  costComponent: '',
                  abmsActivity: '',
                  dept: '',
                  linecost: Number(action.payload.invoiceAmount).toFixed(2),
                  percentage: Number(100).toFixed(2),
                },
              ];

          finalObj.push({
            activity: action.payload.purchaseOrder.ponum,
            costline: invoiceCostsData,
          });
        } else {
          let finalCostLine = [];
          invoiceCostsData = action.payload?.activities?.map(activity => {
            const findInvoiceCosts = action.payload?.invoiceCosts?.filter(
              obj => obj.activitynum === activity.activitynum,
            );
            if (findInvoiceCosts?.length) {
              return findInvoiceCosts.map((item, idx) => {
                const parts = item.gldebitacct.split('-');
                finalCostLine.push({
                  activityNum: activity.activitynum,
                  workorder: parts[3],
                  abmsProject: parts[5],
                  accountNmbr: parts[2],
                  projectBusinessUnit: parts[4],
                  glbu: parts[0],
                  costComponent: parts[7],
                  abmsActivity: parts[6],
                  dept: parts[1],
                  linecost: Number(item.linecost).toFixed(2),
                  percentage: Number(item.percentage).toFixed(2),
                  invoicecostlinenum: item.invoicecostlinenum,
                });
              });
            } else {
              finalCostLine.push({
                activityNum: activity.activitynum,
                workorder: '',
                abmsProject: '',
                accountNmbr: '',
                projectBusinessUnit: '',
                glbu: '',
                costComponent: '',
                abmsActivity: '',
                dept: '',
                linecost: Number(activity.amount).toFixed(2),
                percentage: Number(100).toFixed(2),
              });
            }
          });

          const activityDict = {};
          finalCostLine.forEach(item => {
            const activityNum = item.activityNum;
            if (!activityDict[activityNum]) {
              activityDict[activityNum] = {
                activity: activityNum,
                costline: [],
              };
            }
            activityDict[activityNum].costline.push(item);
          });
          finalObj = Object.values(activityDict);
        }

        const stateValues = { ...state, ...action.payload };
        stateValues.allCostLine = [...finalObj];
        return { ...stateValues };
      } else {
        const stateValues = { ...state, ...action.payload };
        return { ...stateValues };
      }
    },

    setRdaData(state, action) {
      const stateValues = { ...state, ...action.payload };
      return { ...stateValues };
    },

    resetInvoiceDetails() {
      return { ...initialState };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchInvoiceDetails,
  updateInvoice,
  fetchRDADetails,
  setInvoiceDetails,
  setRdaData,
  resetInvoiceDetails,
  setError,
  unsetError,
  sendInvoiceToMaximo,
  setCreateInvoiceError,
  submitToMaximoPSValidation,
  submitToMaximoAttachDocument,
} = invoiceDetailSlice.actions;

export default invoiceDetailSlice.reducer;
